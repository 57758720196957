 <template>
  <div class="navleft-container">
    <el-menu
      class="navleft-menu navLft_active"
      mode="vertical"
      :default-active="$route.path"
      show-timeout="0"
      router
      background-color="#fff"
      text-color="#333"
      active-text-color="#1b84ff"
    >
      
      <template v-for="(item, index) in data">
        <el-submenu  :key="index" :index="'key_' + index" v-if="item.childList && item.childList.length > 0">
          <template slot="title">
            <i :class="item.icon" style="line-height: inherit;"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="item_in.url"
            v-for="(item_in, index_in) in item.childList"
            :key="index_in"
            @click="hideMenu"
          >{{ item_in.name }}</el-menu-item>
        </el-submenu>
        <el-menu-item :key="index" :index="item.url" @click="hideMenu"  v-else >
          <template slot="title">
            <i :class="item.icon" style="line-height: inherit;"></i>
            <span >{{ item.name }}</span>
          </template>
        </el-menu-item>
      </template>


    </el-menu>
  </div>
</template>

 <script>
 import menuList from "@/router/menuConfig.js";
 
export default {
  data() {
    return {
      routeURL: this.$route.path,
      data: menuList
    };
  },
  mounted() {},
  watch: {},
  props: ["menuVisible"],
  methods: {
    hideMenu(){
      this.$emit("update:menuVisible", false);
    }

  }
};
</script>

 <style lang="less">
 .navleft-container{
   position:relative;
   display: inline-block;
   height: 100%;
   background-color:white;
 }

 .navleft-menu{
   width:260px;
   box-sizing: border-box;
   background:#fff;
 }


.navLft_active {
  border: none !important;

  .is-active {
    background: #f3f8ff !important;
  }

  .el-menu-item:hover {
    background-color: #f3f8ff !important;
  }

  .el-submenu__title:hover {
    background-color: #f3f8ff !important;
  }

  .el-submenu__title i {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
 .navleft-container{
   overflow: auto;
 }
}
</style>