<template>
    <div style="margin-bottom:8px;"  v-if="visible" >
        <el-alert :title="warningInfo"  type="warning" show-icon=""></el-alert>
        <el-dialog title="停用提示"  :visible.sync="dialogVisible">
           <div class="warning-wrap">
                <div class="warning-content">
                    <b>{{this.name}} </b>
                    功能正在维护，暂时无法使用
                </div>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible=false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    props:{
        code:{
            type:String,
            required: true,
        }
    },
    data(){
        return {
            visible: false,
            dialogVisible: false,
            name:"",
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        init(){
            //检查是否禁用
            let cacheData = sessionStorage.getItem("modelIntegral");
            if(cacheData != null){
                try{
                    let integralList = JSON.parse(cacheData);
                    //判断状态
                    integralList.forEach((item) => {
                        if(item.code == this.code && item.status == "1"){
                            this.visible = true;
                            this.name = item.name;
                        }
                    });
                }catch(e){
                    console.log("load cache error:" + e);
                }
                
                
            }
        }
    },
    computed: {
        warningInfo: function(){
            return this.name + " 功能正在维护，暂时无法使用";
        }
    }
}
</script>

<style lang="less" scoped>
    .warning-wrap{
        height:300px;
        display: flex;
        text-align:center;

        .warning-content{
            line-height: 1.8;
            font-size:28px;
            margin:auto;
        }
    }
</style>
