import { render, staticRenderFns } from "./SystemNotice.vue?vue&type=template&id=5ff72b58&scoped=true&"
import script from "./SystemNotice.vue?vue&type=script&lang=js&"
export * from "./SystemNotice.vue?vue&type=script&lang=js&"
import style0 from "./SystemNotice.vue?vue&type=style&index=0&id=5ff72b58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff72b58",
  null
  
)

export default component.exports