 <template>
  <el-container>
    <!-- header部分 -->
    <el-header class="homeHeader" height="80px" v-if="solitary" >
      <navtop>
        <el-button
          class="asidemenu-control"
          icon="el-icon-menu"
          @click="switchAsideMenu"
        >
        </el-button>
      </navtop>
    </el-header>

    <el-container
      ref="elMain"
      style="height:calc(100% - 80px);"
      v-if="solitary"
    >
      <!-- 左侧导航 -->
      <el-aside class="el_aside" v-show="asideMenuVisible" style="width: 260px">
        <div class="asidemenu-mask" @click="hideMenu"></div>
        <navleft :menuVisible.sync="asideMenuVisible"></navleft>
      </el-aside>

      <!-- 功能列表 -->
      <transition name="slide-fade">
        <functionFloating
          ref="funFloat"
        />
      </transition>

      <el-main>
        <!-- main部分 -->
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </el-main>

      <!-- loading -->
      <BaseLoading v-if="$store.state.jumpFlag"></BaseLoading>
    </el-container>

    
    <el-container v-if="!solitary">
      <router-view />
    </el-container>

    <!-- 登录弹窗 -->
    <Register v-if="$store.state.loginFlag" ref="Register"></Register>

    <!-- <FloatingTool  v-if="solitary" /> -->
    <!-- <service-tool  v-if="solitary" /> -->
    <!-- <loginAndReg></loginAndReg> -->


  </el-container>
</template>


 <script>
import navtop from "@/components/nav-top.vue";
import navleft from "@/components/nav-left.vue";
import Register from "@/components/public/Register.vue";
// import loginAndReg from "@/views/loginAndReg.vue";
import FloatingTool from "@/components/public/FloatingTool.vue";
import ServiceTool from "@/components/public/ServiceTool.vue";
import BaseLoading from "@/components/public/BaseLoading.vue";
import functionFloating from "@/components/functionList.vue";

import { notLogin, notRegister, executeLogout } from "@/publicApi"; //引入处理未登录用户的api
import { getCurrentVipRecord } from "@/request/api"; //这里是引入VIP请求


export default {
  components: {
    navtop,
    navleft,
    Register,
    FloatingTool,
    ServiceTool,
    BaseLoading,
    functionFloating,
    // loginAndReg,
  },
  data() {
    return {
      solitary: true,
      solitaryList: [{ name: "/TBauthorization" }],
      asideMenuVisible: false,
    };
  },
  created() {
    this.solitaryList.find((item) => {
      if (item.name == this.$route.path) {
        this.solitary = false;
        return true;
      }
    });

    let bridgeToken = this.$comm
      .getUrlParam(location.href, "bridgeToken")
      .split("#")[0];
    if (bridgeToken != "") {
      this.$cookies.set("token", bridgeToken);
      location.search = "";
      return;
    }

    let code = this.$comm.getUrlParam(location.href, "code");
    if (code) {
      console.log("检测到邀请码，自动设置：" + code);
      //设置正确的邀请码
      let validCodeMatch = code.match(/^\w+/);
      if (validCodeMatch != null) {
        localStorage.setItem("inviteCode", validCodeMatch[0]);
      }

      notRegister();
    }

    //初始化广告
    this.$store.dispatch("getValidAdvert");

  },
  mounted() {
    //
  },
  watch: {
    
  },
  methods: {
    
    mainFun() {
      let main = this.$refs.elMain;
      let top = Math.floor(main.$el.scrollTop);
      if (top > 280) {
        this.funFloatShow = true;
      } else {
        this.funFloatShow = false;
      }
    },
    switchAsideMenu() {
      if (this.asideMenuVisible) {
        this.asideMenuVisible = false;
      } else {
        this.asideMenuVisible = true;
      }
    },
    hideMenu() {
      this.asideMenuVisible = false;
    },
  },

  beforeRouteUpdate(to, from, next) {
    // 在当前路由改变，但是该组件被复用时调用
    // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
    // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
    // 可以访问组件实例 `this`

    next();
  },
};
</script>

 <style lang="less">
.homeHeader {
  line-height: 80px;
  height: 80px;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.el-main {
  padding: 0 !important;
}

.el-header {
  padding: 0 !important;
}

.el-container {
  padding: 0;
  height: 100%;
}

.notData {
  padding: 20px;
  font-size: 16px;
  color: #666;
  text-align: center;
  width: 100%;
}

.el_aside {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0px;
  z-index: 99999;
  width: 100% !important;
  background: transparent;

  
  &::-webkit-scrollbar {
    width: 1px;
    height: auto;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #f3f8ff;
    background: #fff;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f3f8ff;
    border-radius: 10px;
    background: #ededed;
  }
}


.asidemenu-control.el-button {
  display: none;
  margin: 16px;
}

.asidemenu-mask {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.2);
}



/deep/.hide_arrow {
  .el-carousel__arrow {
    display: none;
  }
}

// 功能列表动画显隐
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

@media screen and (max-width: 640px) {

  .asidemenu-control.el-button {
    display:block;
  }


}

.liability_tip {
  color: #505050;
  margin-top: 5px;
  margin-bottom: 5px;
  p {
    font-size: 12px;
    line-height: 1.8;
  }
}

.liability-link {
  font-size: 14px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.liability-link:hover {
  text-shadow: 0px 0px 12px #eee;
  color: #409eff;
}


.el-cascader-panel {
  .el-cascader-menu__wrap {
    height: 500px;
    width: 300px;
  }
}
</style>