<template>
    <div class="bordure_box" :class="shadow ? 'shadow_box' : ''" :style="{width:widthStyle}">
        <div class="bordure_title">
            <span class="bordure_title_left"></span>
            <span class="bordure_title_center">{{bordureTitle}}</span>
            <span class="bordure_title_right">{{tipTitle}}</span>
        </div>
        <div class="bordure_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '',
            },
            tip:{
                type:String,
                default:'',
            },
            width: {
                type: String,
                default: '',
            },
            shadow:{
                type:Boolean,
                default:false,
            }
        },
        data() {
            return {
                bordureTitle: this.title,
                widthStyle: this.width,
                tipTitle:this.tip,
            }
        },
        mounted() {},
        methods: {

        },
        watch: {
            title(newVal, oldVal) {
                this.bordureTitle = newVal
            },
            width(newVal, oldVal) {
                let wid = newVal.toString()
                this.widthStyle = wid.toString()
            },
        }
    }
</script>

<style lang="less" scoped>
    .bordure_box {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        background: #fff;

        .bordure_title {
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: rgb(227, 236, 255);
            font-size: 14px;
            font-weight: 400;
            color: #333;
            padding: 0 20px;
            position: relative;
            .bordure_title_left{
                position: absolute;
                left: 20px;
            }
            .bordure_title_right{
                font-size: 12px;
                position: absolute;
                right: 20px;
            }
        }

        .bordure_content {
            box-sizing: border-box;
            background: #fff;
            padding: 20px;
        }
    }

      /* 低阴影 */
  .shadow_box{
    box-shadow: 0 5px 15px 1px hsla(0, 0%, 75%, .2);
  }

  
  @media screen and (max-width: 640px){

    .bordure_box{
        .bordure_content{
            padding:0px;
            width: 100%;
            overflow: auto;
        }
    }

  }
</style>