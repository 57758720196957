import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import Echarts from 'echarts'
import VueCookies from 'vue-cookies'
// import './assets/icon/iconfont.css'
import comm from '@/utils'
import moment from 'moment'	// 时间格式化
import qs from 'qs'


import MainTop from '@/components/public/mainTop.vue'
import BordureRow from '@/components/public/bordureRow.vue';
import Autolayout from '@/components/public/autoLayout.vue';
import WarningTip from '@/components/public/warningTip.vue';
import AdvertView from '@/components/public/AdvertView.vue';
import SyncLoading from "@/components/shujuyu/SyncLoading.vue";
import taobaoTaskType from './utils/task-type-tb'
import jdTaskType from './utils/task-type-jd'
import pddTaskType from './utils/task-type-pdd'


Vue.prototype.$qs = qs
Vue.prototype.echarts = Echarts
Vue.prototype.$comm = comm
Vue.prototype.$taobaoTaskType = taobaoTaskType
Vue.prototype.$jdTaskType = jdTaskType
Vue.prototype.$pddTaskType = pddTaskType
Vue.prototype.$moment = moment
Vue.prototype.$store = store


//引入常用方法
//日期
import time from '@/utils/time_utils'
Vue.prototype.$time = time

//正则验证
import check from '@/utils/check_utils'
Vue.prototype.$check = check

//图片
import img_utils from '@/utils/img_utils'
Vue.prototype.$imgUtils = img_utils


Vue.use(VueCookies)
if (Vue.$cookies.get('token')) {
  store.commit('alterToken', Vue.$cookies.get('token'))
}

Vue.use(Echarts)
Vue.use(ElementUI)
//全局跳转路由函数
Vue.prototype.toRoute = function (path, params) {
  if(/^https?:\/\//.test(path)){
      window.open(path);
      return;
  }

  if (path == -1) {
    router.back(-1)
  } else {
    router.push({
      path: path,
      query: params
    });
  }
}

  Vue.component('main-top', MainTop)
  Vue.component('bordure-row', BordureRow)
  Vue.component('auto-layout', Autolayout)
  Vue.component("warning-tip", WarningTip)
  Vue.component("advert-view", AdvertView)
  Vue.component('SyncLoading', SyncLoading)
  
/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
