<template>
  <div class="header-in">
    <slot></slot>
    <!-- 头部标题 -->
    <div class="logo">
      <img :src="logUrl"  class="logo-img" />
    </div>
    <!-- 头部菜单 -->
    <div style="flex:1 1 auto;display: flex;padding:0 20px;">
      <el-menu class="topnav-menu" mode="horizontal" @select="handleSelect" :default-active="$route.path"
        show-timeout="0" background-color="#fff" text-color="#333" active-text-color="#1b84ff" router>

        <template v-for="(item, index) in data" >
          <el-submenu :key="index" :index="`${index} + 1`" v-if="item.childList && item.childList.length != 0">
            <template slot="title">
              <div class="menu_item">{{ item.name }}
                <span class="menu_item_tag" v-if="item.tag"
                  :style="{backgroundColor:item.tag.color}"  :title="item.tag.name">{{item.tag.name}}</span>
                <i class="menu_item_icon" :style="{backgroundImage:'url('+item.mark+')'}"></i>
              </div>
            </template>
            <el-menu-item :index="item_in.url" v-for="(item_in, index_in) in item.childList" :key="index_in">
              {{ item_in.name }}
            </el-menu-item>
          </el-submenu>

          <el-menu-item :index="item.url" :key="index" style="height: 79px" v-else>
            <div style="line-height: 79px;font-weight: 700;font-size: 14px;" class="menu_item">
              <span>{{ item.name }}</span>
              <span class="menu_item_tag" v-if="item.tag"
                :style="{backgroundColor:item.tag.color}">{{item.tag.name}}</span>
            </div>
          </el-menu-item>

        </template>

      </el-menu>
      <!-- 头部右侧按钮 -->
      <div class="nav-rigth" v-if="$store.state.token != null">

        <div class="nav-rigth-personal">
          <div class="personalBtn" style="margin-right: 10px" @click="goPersonalCenter">
            <i class="iconfont" style="font-size: 20px">&#xe612;</i>
            个人中心
          </div>
          <div class="logout" @click="logOut">
            退出
          </div>
        </div>
      </div>
      <div class="nav-rigth" v-if="$store.state.token == null">
        <!-- 取消注册功能 -->
        <!-- <div class="headToTailColor btn" style="margin-right: 10px" @click="inRegister">
          <i class="iconfont" style="font-size: 20px">&#xe612;</i>
          注册
        </div> -->
        <div class="headToTailColor btn " @click="inLogin">
          登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    notLogin,
    notRegister,
    executeLogout
  } from "@/publicApi"; //引入处理未登录用户的api

  import menuList from "@/router/menuConfig.js";

  export default {
    data() {
      return {
        data: menuList,
        token: null,
        isReload: true
      };
    },
    components: {
    },
    created() {
      this.token = this.$store.state.token;
    },
    mounted(){
      
    },
    methods: {
      
      handleSelect(command) {
        this.$router.push({
          path: command
        });
      },
      handleCommand(command) {
        this.$router.push({
          path: command
        });
      },
      //利用sessionStorage临时存储title下标的状态，解决刷新后状态清0
      // alterTitle_index(index) {
      //   this.$store.commit("alterTitle_index", index); //使用vuex状态管理改变下标
      // },
      // 去个人中心
      goPersonalCenter() {
        this.$router.push({
          path: "/personalCenter"
        });
      },
      // 退出登录
      logOut() {
        executeLogout();
      },
      inRegister() {
        notRegister();
      },
      inLogin() {
        notLogin();
      }

    },
    computed:{
      title(){
        return this.$store.state.title;
      },
      logUrl(){
        let defaultUrl = require('@/assets/imgs/logo.png');
        let siteInfo = this.$store.state.siteInfo;
        if(siteInfo){
          return siteInfo.siteIcon;
        }

        return defaultUrl;
      }
    }
  };
</script>

<style lang="less">
  .header-in {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .el-menu.el-menu--horizontal,
    .is-active,
    .el-submenu__title {
      border: none !important;
    }

    .el-menu-item,
    .el-submenu__title {
      padding: 5px !important;
    }

    .is-active,
    .el-submenu__title:hover,
    .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
      background: #fff !important;
      color: #1b84ff !important;
    }

    .menuMinWidth {
      width: 800px;
    }

    .el-menu--popup {
      z-index: 100;
      border: none;
      padding: 5px 0;
      border-radius: 2px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 73%);
    }

    .topnav-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }

    .nav-rigth {
      display: flex;
      padding: 0px 8px;
      width: 320px;
      font-size: 14px;
      // justify-content: flex-end;
    }

    // 标题
    .logo {
      display:flex;
      justify-content:flex-end;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 28px;
      color: #409eff;
      letter-spacing: 0;
      text-align: right;
      width: 240px;
      white-space: nowrap;

      .logo-img{
        width:210px;
        height:60px;
        max-height:100%;
      }
    }

    // 头部中间导航栏
    .navigation {
      min-width: 600px;
      line-height: 79px;
      font-size: 20px;

      .header-title {
        position: relative;
        // height: 80px;
        cursor: pointer;

        .header-title-underline {
          position: absolute;
          width: 100%;
          height: 5px;
          bottom: -20px;
          background-color: #17b3a3;
        }
      }
    }
  }

  .el-submenu__title {
    height: 79px !important;
    display: flex;
    line-height: 79px !important;
  }

  .el-submenu__title i {
    display: none;
  }

  .el-submenu__icon-arrow,
  .el-icon-arrow-down {
    display: none;
  }

  .nav-rigth-vip {
    display: flex;
    align-items: center;
    line-height: 30px;
    color: #333;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;

    .nav-rigth-p {
      line-height: 1.5;
    }
  }

  .nav-rigth-personal {
    display: flex;
    align-items: center;
  }

  .personalBtn {
    background: #6d89ff !important;
    border-radius: 18px;
    text-align: center;
    color: #ffffff;
    width: 100px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 12px;
  }

  .personalBtn:hover {
    background: #5572e8 !important;
    color: #fff !important;
  }

  .logout {
    color: #333;
    letter-spacing: 0;
    width: 40px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
  }

  .menu_item {
    line-height: 79px;
    font-weight: 700;
    font-size: 14px;
    position: relative;

    .menu_item_tag {
      position: absolute;
      top: 12px;
      left: 50%;
      margin-left: -50%;
      font-size: 12px;
      background: #69f;
      color: #fff;
      height: 12px;
      line-height: 12px;
      padding: 2px;
      border-radius: 2px;
    }

    .menu_item_icon {
      display: block;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 29px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }


  @media screen and (max-width: 640px) {

    .header-in {
      width: 100%;
      justify-content: flex-end;

      .nav-rigth {
        width: 100%;
        justify-content: flex-end;
      }

      .logo {
        display: none;
      }

    }

    

    .topnav-menu {
      display: none !important;
    }

  }
</style>