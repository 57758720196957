<template>
    <div>
        <el-dialog title="宝贵建议" :visible.sync="suggestPup"   @close="suggestClose"  class="suggest-dialog">
            <el-form :model="suggestForm" ref="suggestForm" :rules="rules" :inline="false" size="normal"
                style="padding:0 40px;">
                <el-form-item label="标题：" prop="adviceTitle">
                    <el-input v-model="suggestForm.adviceTitle" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="内容：" prop="adviceContent">
                    <el-input type="textarea" rows="5" resize="none" v-model="suggestForm.adviceContent" placeholder="请输入内容"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" style="padding:0 40px;">
                <el-button type="primary" @click="suggestSubmit('suggestForm')" style="width:100px;">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        suggestMit
    } from "../../request/api";
    export default {
        props: {
            pupopBus:{
                default:false,
                type:Boolean
            },
        },
        data() {
            return {
                suggestPup: false,
                suggestForm: {
                    adviceTitle: '',
                    adviceContent: ''
                },
                rules: {
                    adviceTitle: {
                        required: true,
                        message: '请输入标题',
                        trigger: 'blur'
                    },
                    adviceContent: {
                        required: true,
                        message: '请输入内容',
                        trigger: 'blur'
                    },
                }
            }
        },
        methods: {
            suggestClose(){
                this.$emit('suggestBus',false)
            },
            suggestSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        suggestMit(this.suggestForm).then((data) => {
                            this.suggestForm.adviceTitle = ''
                            this.suggestForm.adviceContent = ''
                            this.$message.success('提交成功,感谢您的支持！')
                        }).finally(() => {
                            this.$emit('suggestBus',false)
                        });
                    }

                });
            }
        },
        watch:{
            pupopBus(newVal,oldVal){
                this.suggestPup = newVal
            }
        }
    }
</script>

<style lang="less" scoped>

    .suggest-dialog {

        .el-dialog{
            max-width: 600px;
            width: 90%;
        }


    }
    
</style>