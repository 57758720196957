import Vue from 'vue'
import router from '@/router'
import store from './store'
import { logout} from "@/request/api"; //这里是引入请求

let vue = new Vue();


/**
 * 打开登录页面
 * 
 */
export function notLogin() {
    store.commit("alterLoginFlag", true);
    store.commit("alterRegFlag", true);
}

/**
 * 打开注册页面
 */
export function notRegister(){
    store.commit("alterLoginFlag", true);
    store.commit("alterRegFlag", false);
}

/**
 * 执行退出
 */
export function executeLogout(){
    logout().then(data => {
        vue.$message({
            message: "已退出登录,正在重新加载...",
            type: "warning"
        });
        vue.$store.commit("clearToken");

        setTimeout(() => {
          location.reload();
        }, 1000);

    });
}



