export default {

  getMenuList() {
    return menuList
  },
  getTaskType(name){
    for (var i = 0; i < menuList.length; i++) {

      if (menuList[i].name == name) {
        return menuList[i]
      }
    }
  },
  getTaskIndex(num){
    return menuList[num];
  },
}
  var menuList = [{
        name: '流量任务',
        type: [{
            name: '手淘搜索真实访客',
            taskType: '11',
            keyword:true,
            showStatus:true,//是否展现
          },
          {
            name: '指定账号多天回访',
            taskType: '12',
            keyword:true,
            showStatus:true,//是否展现
            dayNumIgnore:true,
            returnVisitStatus:true,
          }, 
          // {
          //   name: '宝贝推荐（宝贝点赞）',
          //   taskType: '14',
          //   residenceTimeIgnore:1,
          //   showStatus:false,
          //   bbdz:true,
          //   keywordsIgnore:true,
          //   residenceTime_viewGoodIgnore:true,
          //   viewComment_attachIgnore:true,
          //   hcl_taoCreditLevelIgnore:true,
          //   sexTypeIgnore:true,
          //   visitorTagTypeIgnore:true,
          // },
          {
            name: '手淘推荐（猜你喜欢）',
            taskType: '15',
            titleIgnore:1,
            attachIgnore:1,
            showStatus:false,
          },
        ]
      },
      {
        name: '收藏任务',
        type: [{
            name: '商品收藏',
            taskType: '21',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
            hcl_taoCreditLevelIgnore:true,
            sexTypeIgnore:true,
            visitorTagTypeIgnore:true,
          },
          {
            name: '店铺关注',
            taskType: '22',
            url: '店铺链接',
            requestDesc:'请输入店铺首页链接',
            describe: '店铺关注任务的“每天发布量”需要大于500, 且由于近期淘宝官方对粉丝营销严重风控，导致淘宝粉丝任务执行量会与发布量有两成左右的差量，此属于不可抗力因素，所以不补不退~会员请谨慎下单！对此给您带来的不便，我们深感歉意',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
            hcl_taoCreditLevelIgnore:true,
            sexTypeIgnore:true,
            visitorTagTypeIgnore:true,
            shopStatus:true,
            runDayCommit:true,
          },
          {
            name: '搜索收藏',
            taskType: '23',
            keyword:true,
            hcl_taoCreditLevelIgnore:true,
            sexTypeIgnore:true,
            visitorTagTypeIgnore:true,
          },
        ]
      },
      {
        name: '加购任务',
        type: [{
            name: '商品加购',
            taskType: '31',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
            hcl_taoCreditLevelIgnore:true,
            sexTypeIgnore:true,
            visitorTagTypeIgnore:true,
            // describe: '当前加购功能维护，部分任务无法执行，暂时不要发布。其他任务一切正常，具体恢复时间等待通知'
          },
          {
            name: '搜索加购',
            taskType: '32',
            keyword:true,
            hcl_taoCreditLevelIgnore:true,
            sexTypeIgnore:true,
            visitorTagTypeIgnore:true,
            // describe: '当前加购功能维护，部分任务无法执行，暂时不要发布。其他任务一切正常，具体恢复时间等待通知'
          },
        ]
      },
      // {
      //   name: '关注任务',
      //   type: [
      //     {
      //       name: '微淘点赞',
      //       taskType: '41',
      //       tklStatus:true,
      //       requestDesc:'微淘分享口令',
      //       describe: '淘口令仅需要输入特殊符号内的字母数字，例如：“6.0《UKKHmX2cAoni₤ https://m.tb.cn/h.bdd?sm=xx 日系xx模板xx”，仅需 “UKKHmX2cAoni”',
      //       residenceTime_viewGoodIgnore:true,
      //       viewComment_attachIgnore:true,
      //       hcl_taoCreditLevelIgnore:true,
      //       sexTypeIgnore:true,
      //       visitorTagTypeIgnore:true,
      //       tklStatus:true,
      //     },
      //     {
      //       name: '商品评论点赞',
      //       requestDesc:'评论分享口令',
      //       //describe: '淘口令仅需要输入特殊符号内的字母数字，例如：“8.0₴HcMBxl2XdAKA🗝 https://m.tb.cn/h.kk?sm=pp” 好看 ,仅需 “HcMBxl2XdAKA”',
      //       describe: '淘宝商品评论点赞暂时维护无法发布，其他任务一切正常，具体恢复时间等待通知。',
      //       taskType: '42',
      //       residenceTime_viewGoodIgnore:true,
      //       viewComment_attachIgnore:true,
      //       hcl_taoCreditLevelIgnore:true,
      //       sexTypeIgnore:true,
      //       visitorTagTypeIgnore:true,
      //       tklStatus:true,
      //     },
      //     {
      //       name: '逛逛点赞',
      //       requestDesc:'逛逛分享口令',
      //       //describe: '淘口令仅需要输入特殊符号内的字母数字，例如：“4.0《UIIHmX2cAoni₤ https://m.tb.cn/h.bdd?sm=xx 日系xx模板xx”，仅需“UIIHmX2cAoni”',
      //       describe: '淘口令需要输入完整的分享地址，例如：【淘宝】https://m.tb.cn/h.53j9Xcv?tk=Ky6odC6Q4lp CZ0001 「xxx」点击链接直接打开 或者 淘宝搜索直接打开',
      //       taskType: '43',
      //       residenceTime_viewGoodIgnore:true,
      //       viewComment_attachIgnore:true,
      //       hcl_taoCreditLevelIgnore:true,
      //       sexTypeIgnore:true,
      //       visitorTagTypeIgnore:true,
      //       tklStatus:true,
      //     },
         
      //   ]
      // },
    ]
