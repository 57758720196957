export default {

  getMenuList() {
    return menuList
  },
  getTaskType(name){
    for (var i = 0; i < menuList.length; i++) {
      if (menuList[i].name == name) {
        return menuList[i]
      }
    }
  },
  getTaskIndex(num){
    return menuList[num];
  }
}
  var menuList = [{
        name: '流量任务',
        type: [{
            name: '拼多多搜索真实访客',
            taskType: '11',
            keyword:true,
            titleStatus:true,
            showStatus:true,//是否展现
            urlLimit:'goods_id',
          },
        ]
      },
      {
        name: '收藏任务',
        type: [{
            name: '搜索收藏',
            taskType: '23',
            keyword:1,
            titleStatus:true,
            urlLimit:'goods_id',
            // describe: '当前暂时维护无法发布，其他任务一切正常，具体恢复时间等待通知。',
          },
          {
            name: '商品收藏',
            taskType: '21',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
            urlLimit:'goods_id',
            describe: '当前暂时维护无法发布，其他任务一切正常，具体恢复时间等待通知。',
          },
          {
            name: '店铺关注',
            taskType: '22',
            request:'店铺链接',
            requestDesc:'请输入店铺链接',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
            shopStatus:true,
            urlLimit:'mall_id',
            // describe: '当前暂时维护无法发布，其他任务一切正常，具体恢复时间等待通知。',
          },
        ]
      },
    ]
