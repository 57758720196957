<template>
  <el-dialog class="login"  :visible="true"  :show-close="false" >
    <!-- 登陆 -->
    <div  class="login-in" slot="default" v-loading="loading">
      <i class="iconfont del" @click="del">&#xe613;</i>

      <div class="login-left"  >

        <img :src="registerAdvert.source"  alt="注册宣传图"  @click="toRoute(registerAdvert.linkUrl)"/>

        <!-- <div class="login-tip">
          <p class="login-tip-title">注册账号领取一下特权：</p>
          <p>1.注册即赠送200积分</p>
          <p>2.加客服微信再次赠送300积分</p>
          <p>3.加客服微信赠送店铺运营资料</p>
        </div> -->
      </div>
      <div class="login-panel" v-if="passwordPop">

        <div class="welcome">
          <span v-if="loginOrReg">
              欢迎登录
          </span>
          <span v-else>
            欢迎注册
          </span>
        </div>
        <div class="littleTitle"  >{{title}}</div>
        <div v-if="loginOrReg" class="login-body">
          <el-form class="content" :model="dataForm" :rules="dataRule" ref="dataForm"
            @keyup.enter.native="dataFormSubmit()" status-icon>
            <!-- <div class="login-method" @click="qrFormLogin">
              <img src="@/assets/imgs/computer-icon.png" v-if="isQrLogin"/>
              <img src="@/assets/imgs/qr-icon.png" v-if="!isQrLogin"/>
            </div> -->
            <div class="form-area" v-show="!isQrLogin">
              <el-form-item label="用户电话" prop="userName">
                <el-input v-model="dataForm.userName" placeholder="请输入您的电话号码"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="dataForm.password" type="password" placeholder="请输入您的密码"></el-input>
              </el-form-item>
              <div style="text-align: right;"><el-checkbox v-model="SavePassword">记住密码</el-checkbox></div>
              
              <div class="loginBtn" @click="dataFormSubmit()">登 录</div>
            </div>

            <div class="qr-area" v-show="isQrLogin" >
                <div>
                  <Qrcode  :value="authorizationUrl" :options="qrOptions"  class="author-img"></Qrcode>
                </div>
                <div class="qr-tips">
                    打开微信，扫一扫登录
                </div>
            </div>

            <!-- 取消注册功能跳转  -->
            <!-- <div class="registerBtn" @click="goRegister">注 册</div> -->

            <div class="to-forgetpasswork" >
              <!-- <span class="to-link-help" style="font-weight:bold;" @click="qrFormLogin" >
                  <span v-if="isQrLogin">用户名密码登录</span>
                  <span v-else><i class="wxIcon" :style="{backgroundImage:'url('+require('@/assets/imgs/weixinIcon.png')+')'}"></i>扫码登录</span>
              </span> -->
              <span class="to-link-help" @click="toForGetPassWork">忘记密码？</span>
            </div>
          </el-form>
        </div>



        <!-- 注册 -->
        <div class="register-body" v-if="!loginOrReg">
          <el-form :rules="registerFormRules" ref="registerForm" :model="registerForm" label-position="right"
            show-message class="content">
            <el-form-item label="用户电话" prop="memberPhone">
              <el-input v-model="registerForm.memberPhone" placeholder="请输入手机号" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="registerForm.password" placeholder="请输入密码" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="sendcode" class="code">
              <div style="display: flex;align-items: center; width:100%;">
                <el-input v-model="registerForm.sendcode" placeholder="请输入验证码"></el-input>
                <el-button style="margin-left: 5px" type="button" @click="sendcode" :disabled="codeDisabled">{{ btntxt }}
                </el-button>
              </div>
            <div class="inviteText" v-if="registerForm.inviteCode"> 邀请码：<span>{{registerForm.inviteCode}}</span></div>
            </el-form-item>
            <div style="text-align: center">
              <el-button type="primary" style="width: 100%;" @click="registerSubmit('registerForm')" >
                <span>立即注册</span>
              </el-button>
              <div class="registerQuestion">
                <div>
                <el-checkbox v-model="registerForm.liability"></el-checkbox> <router-link tag="a" target="_blank" :to="{path:'/statement'}" style="color: #5d7cff;">免责声明</router-link>
                </div>
                <div>
                  已有账号？<span style="color: #5d7cff; cursor: pointer" @click="tologin">登录</span>
                </div>
              </div>
            </div>
          </el-form>
        </div>


      </div>



      <!-- 修改密码 -->
      <div class="forget-panel" v-if="!passwordPop">
        <p class="welcome" style="width: 380px;">忘记密码</p>
        <div class="forget-body">
        <el-form :model="passWordData" ref="passWordDataForm" :rules="passWordDataRules" size="normal" >
          <el-form-item label="用户名：" prop="username" class="forget-item">
            <el-input v-model="passWordData.username" placeholder="请输入用户名" width="100%"></el-input>
          </el-form-item>
          <el-form-item label="重置密码：" prop="password" class="forget-item">
            <el-input type="password" v-model="passWordData.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="sendcode" class="forget-item">
            <el-input v-model="passWordData.sendcode" placeholder="请输入验证码">
              <el-button type="primary" size="default" slot="append" :disabled="codeDisabled" @click="codeSend"
                style="width:112px;">
                <span v-if="codeDisabled" style="">{{mfTimes}}s</span>
                <span v-else style="">获取验证码</span>
              </el-button>
            </el-input>
          </el-form-item>
          <div style="margin:35px auto 30px;">
            <el-button type="primary" size="default" @click="formSubmit('passWordDataForm')" style="width:100%;">提交
            </el-button>
          </div>
          <div style="text-align:left;" v-if="!this.$store.state.token">
            <span @click="passwordPop = true" class="to-login"><i class="el-icon-arrow-left"></i> 去登录</span>
          </div>
        </el-form>
        </div>
      </div>


    </div>
  </el-dialog>
</template>

<script>
  import {
    login,
    sendVerificationCode,
    register,
    modificationPW,
    modificationCode,
    authorize,
    getWxState
  } from "@/request/api"; //这里是引入请求
  import Qrcode from "@xkeshi/vue-qrcode";

  export default {
    data() {
      return {
        dialogVisible: true,
        loginOrReg: this.$store.state.RegFlag, //登陆还是注册
        passwordPop: this.$store.state.changePWFlag,
        dataForm: {
          userName: "",
          password: "",
          userType: "22",
          platform:'pc'
        },
        SavePassword:false,//记住密码
        dataRule: {
          userName: [{
            required: true,
            message: "帐号不能为空",
            trigger: "blur"
          }, ],
          password: [{
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }, ],
          captcha: [{
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }, ],
        },
        captchaPath: "",

        //以下为注册表单相关数据
        checkPhone: false, //电话号码是否通过
        time: 0, //时间
        btntxt: "获取验证码",
        activeName: "entryPage",
        registerForm: {
          //注册表单
          sendcode: "",
          memberPhone: "",
          password: "",
          agentId: "32",
          upMemberId: "0",
          inviteCode: '',
          liability:false,
        },
        registerFormRules: {
          memberPhone: [{
              required: true,
              pattern: /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
            {
              max: 11,
              message: "请输入11位手机号",
              trigger: "blur"
            },
          ],
          password: [{
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }, ],
          sendcode: [{
            required: true,
            message: "请先获取验证码",
            trigger: "blur"
          }, ],
        },
  
        // 忘记密码
        passWordData: {
          username: '',
          password: '',
          sendcode: '',
        },
        mfTimes: 60,
        codeDisabled: false,
        passWordDataRules: {
          username: [{
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入重置密码',
            trigger: 'blur'
          }],
          sendcode: [{
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }]
        
        },
        isQrLogin: false,
        authorizationUrl: "Data is not loaded. Please try again later",
        qrOptions:{
          size: 220
        },
        checkTimeval: null,
        loading:false,
      };
    },
    created() {
      if(this.$cookies.get('SaveLogin')){
        this.SavePassword = true
        let loginObj = this.$cookies.get('SaveLogin')
        this.dataForm.userName = loginObj.userName
        this.dataForm.password = loginObj.password
      }
      clearInterval(this.checkTimeval);
      this.registerForm.inviteCode = localStorage.getItem('inviteCode')
      if (
        this.$route.query.agentId != undefined ||
        this.$route.query.upMemberId != undefined
      ) {
        this.registerForm.agentId = this.$route.query.agentId.replace(
          /(^\s*)|(\s*$)/g,
          ""
        );
        this.registerForm.upMemberId = this.$route.query.upMemberId.replace(
          /(^\s*)|(\s*$)/g,
          ""
        );
      }
    },
    components: {
      Qrcode
    },
    methods: {
      goRegister() {
        // this.$router.push({ path: "/register" });
        this.loginOrReg = !this.loginOrReg
      },
      del() {
        this.$store.commit("alterLoginFlag", false);
        this.$store.commit("alterChangePWFlag", true);
        clearInterval(this.checkTimeval);
      },
      // 提交登录表单
      dataFormSubmit() {
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            this.loading = true
            login(this.dataForm).then((data) => {
              if(this.SavePassword){
                this.$cookies.set('SaveLogin', this.dataForm,"30d")
              }
              if(!this.SavePassword){
                this.$cookies.remove('SaveLogin')
              }

              this.$store.commit("alterToken", data.token);
              this.$store.commit("alterLoginFlag", false);
              this.$cookies.set('token', data.token)

              this.$message({
                message: "登录成功，马上重新加载...",
                type: "success",
                duration: "1000"
              });

              //直接reload当前页
              let vue = this;
              setTimeout(function(){
                if(vue.$route.path == "/page"){
                  location.href = "/";
                  return;
                }
                location.reload(true);
              },500);
              
            }).finally(()=>{
              this.loading = false
            });
          }
        });
      },

      // 注册
      // 去登陆
      tologin() {
        this.loginOrReg = !this.loginOrReg
      },
      //60S倒计时
      timer() {
        if (this.time > 0) {
          this.time--;
          this.btntxt = this.time + "s后重新获取";
          setTimeout(this.timer, 1000);
        } else {
          this.time = 0;
          this.btntxt = "获取验证码";
          this.codeDisabled = false;
        }
      },

      // 手机发送验证码
      sendcode() {
        const reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
        if (this.registerForm.memberPhone == "") {
          this.$message({
            message: "手机号不能为空",
            center: true,
          });
          return;
        }
        if (!reg.test(this.registerForm.memberPhone)) {
          this.$message({
            message: "请输入正确的手机号",
            center: true,
          });
          return;
        } else {
          sendVerificationCode({
            memberPhone: this.registerForm.memberPhone,
          }).then((data) => {
            this.checkPhone = true;
          });
          this.time = 60;
          this.codeDisabled = true;
          this.timer();
        }
      },
      // 为注册表单绑定验证功能
      registerSubmit(formName) {
        // if (this.checkPhone) {
        //判断验证码是否已发送
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(!this.registerForm.liability){
                this.$message({
                  message: "请阅读并同意免责声明",
                  type: "warning",
                  duration: 1500,
                });
                return false
            }
            this.loading = true
            //验证注册表单是否满足所有条件
            register(this.registerForm).then((data) => {
              //注册成功改变Tab的状态跳到登录页
              this.$message({
                message: "注册成功，请登录",
                type: "success",
                duration: 1500,
              });
              this.loginOrReg = !this.loginOrReg
              this.registerForm.memberPhone = ''
              this.registerForm.password = ''
              this.registerForm.sendcode = ''
              this.activeName = "entryPage"; //改变tab状态
            }).finally(() => {
              this.loading = false;
            });
          } {}
        });
        // } else {
        //   this.checkPhone = false; //如果验证码不通过得话
        //   this.$message({
        //     message: "请先获取验证码",
        //     center: true,
        //   });
        // }
      },



      // 修改密码
      toForGetPassWork() {
        this.passwordPop = !this.passwordPop
      },
      timeCode() {
        this.codeDisabled = true
        this.mfTimer = setInterval(() => {
          this.mfTimes--
          if (this.mfTimes === 0) {
            this.codeDisabled = false
            this.mfTimes = 60
            clearInterval(this.mfTimer)
          }
        }, 1000)
      },
      codeSend() {
        if (this.passWordData.username == '') {
          this.$message.error('请输入用户名')
          return false
        }
        modificationCode({
          username: this.passWordData.username
        }).then((data) => {
          this.timeCode()
          this.$message.warning('验证码已发送');
        }).catch(e => {
          console.log(e)
        })
      },
      formSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            modificationPW(this.passWordData).then((data) => {
              this.passwordPop = true
                this.passWordData = {
                  username: '',
                  password: '',
                  sendcode: '',
                }

              if (this.$store.state.token) {
                this.$message.success("修改成功，请重新登陆");
                this.$store.commit("clearToken");
                setTimeout(() => {
                  this.$router.push({
                    path: "/page"
                  });
                }, 1000);
              } else {
                this.$message.success(data.msg);
              }
            }).catch(e => {
              console.log(e)
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      qrFormLogin(){
        clearInterval(this.checkTimeval);
        if(this.isQrLogin){
            this.isQrLogin = false;
        }else{
          this.isQrLogin = true;
          authorize({}).then((data) => {
              this.refreshBtnDisabled = false;
              //检查结果
              this.authorizationUrl = data.authorizationUrl;
              this.checkResult(data.authorizeId);
          });
        }
        
      },
      checkResult(authorizeId){
        clearInterval(this.checkTimeval);
        this.checkTimeval = setInterval(() => {
          getWxState({authorizeId:authorizeId}).then((data) => {
              if(data.authorized == 1){
                clearInterval(this.checkTimeval);
                if(data.token){
                  this.$store.commit("alterToken", data.token);
                  this.$store.commit("alterLoginFlag", false);
                  this.$cookies.set('token', data.token)
                  this.$message({
                    message: "登录成功，马上重新加载...",
                    type: "success",
                    duration: "500"
                  });

                  //直接reload当前页
                  let vue = this;
                  setTimeout(function(){
                    if(vue.$route.path == "/page"){
                      location.href = "/";
                      return;
                    }
                    location.reload(true);
                  },500);
                  
                }
              }else{
                console.log("等待用户扫码登录：" + authorizeId , data);
              }

          }).catch(() => {
            clearInterval(this.checkTimeval);
          });
          
        }, 1000);
        
      }

    },
    computed:{
      title(){
        return this.$store.state.title;
      },
      registerAdvert(){
        let defaultUrl = require("@/assets/imgs/login_bg.jpg");
        let advertItem = {source:defaultUrl,linkUrl: undefined};
        let advertList = this.$store.state.advertList;
        if(advertList){
            advertList.forEach((item) => {
              if(item.type == "register"){
                  advertItem = item;
              }
            });
        }

        return advertItem;

      }
    }
  };
</script>

<style lang="less" scoped>
  .login {
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    // position: fixed;
    // z-index: 2000;
    // text-align:center;
    
     /deep/.el-dialog{
        max-width: 800px;
        width: 90%;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
     }

    /deep/.el-dialog__header{
      display:none;
    }
    
    .login-in {
      display:flex;
      width: 100%;
      max-height: 600px;
      overflow: auto;

      // display: inline-block;
      // padding: 0 30px 40px 50px;
      // box-sizing: border-box;
      // height: 80%;
      // max-height: 580px;
      // max-width: 800px;
      // width: 90%;
      // background: #ffffff;
      // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      // border-radius: 10px;
      // position: relative;
      // margin-top: 10%;
      // text-align: center;
      // overflow: auto;
      .del {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        color: #a9adcc;
        cursor: pointer;
        z-index:999;
      }


      .welcome {
        /* font-size: 32px;
      color: #333333;font-weight: 600; */
        margin-top: 70px;
        font-family: "PingFangSC-Medium";
        font-size: 32px;
        color: #333333;
        letter-spacing: 0.31px;
      }

      .littleTitle {
        font-size: 14px;
        color: #999999;
        margin-top: 15px;
      }

      .content {
        position:relative;
        margin-top: 20px;
        box-sizing: border-box;

        /deep/.el-form-item__label {
          font-size: 14px;
          color: #111111;
          line-height: 22px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }

      .loginBtn,
      .registerBtn {
        margin-top: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        line-height: 40px;
        font-family: "微软雅黑";
        cursor: pointer;
      }

      .loginBtn {
        color: #ffffff;
        background: #5d7cff;
      }

      .registerBtn {
        border: 1px solid #5d7cff;
        color: #5d7cff;
      }

    }
  }


  .registerQuestion {
    font-size: 14px;
    text-align: right;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  .login-left {
    float: left;
    width: 50%;
    max-height: 100%;
    // padding-left: 20px;

    img{
      max-width:100%;
      max-height: 100%;
    }

  }

  .login-panel, .forget-panel{
    position:relative;
    float: left;
    width: 50%;
    height: 100%;
    background-color:white;
    text-align:center;
  }



  .login-body{
    position:relative;
    padding:0px 50px;
  }

  .login-qr {
    width: 300px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .login-tip {
    font-size: 22px;
    color: #a76060;
    margin-top: 105px;
    margin-bottom: 30px;
    text-align: left;
    line-height: 50px;

    .login-tip-title {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  .to-forgetpasswork {
    margin-top: 40px;
    font-size: 14px;
    cursor: pointer;
    text-align: right;
  }

  .to-link-help{
    color: #5d7cff;
    margin-right:20px;
  }

  .forget-item {
    margin-bottom: 10px;
  }

  .to-login {
    font-size: 14px;
    color: #5d7cff;
    cursor: pointer;
  }

  .register-body{
    padding: 0px 50px;
  }

  .forget-body{
    padding: 0px 50px;
  }

  .login-method{
    position:absolute;
    top: -130px;
    right: -5px;
    z-index:100;
    width:60px;
    height:60px;
    cursor:pointer;
    img{
      max-width:100%;
      max-height: 100%;
    }

  }

  .form-area,.qr-area{
    min-height:230px;
    text-align:center;
  }
  
  .qr-tips{
    font-size:12px;
    padding:0px;
    text-align:right;
  }

  .inviteText{
    text-align: left;
    line-height: 30px;
    span{
      color: #ababab;
    }
  }

  @media screen and (max-width: 640px){
    .login-left{
      display: none;
    }

    .login-panel, .forget-panel{
      width: 100%;
    }

    .login-body,.forget-body, .register-body{
      padding:0px;
    }

    .login{
        .login-in{
          .welcome{
            margin-top: 20px;
          }
        }
        
    }
  }

  .wxIcon{
    display: inline-block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    vertical-align: bottom;
    margin-right: 5px;
  }
</style>