<template>
  <el-dialog title="充值中心" :visible.sync="visible" :append-to-body="true" @close="closeDialog">
    <div v-show="num == 1">

      <div class="recharge_box" v-if="selective">
        <div class="recharge_item" v-for="(item,index) in rechargeList" :key="index"
          :style="{'backgroundImage': 'url('+require('@/assets/imgs/recharge_bg.jpg')+')'}">
          <template v-if="item.rechargeMoney == dataForm.money">
            <!-- <div v-if="item.vipdetails" class="vip_details">
              <p class="vip_name" :style="{color:item.vipdetails.color}">{{item.vipdetails.vipName}}</p>
              <p class="vip_authority">*{{item.vipdetails.memberVipDiscountVoList[0].discountName}}</p>
            </div> -->
            <div class="recharge_item_content">
              <div class="discount"
                v-if="item.calDiscount<10">
                <b>{{item.calDiscount}}</b> 折
              </div>
              <div class="recharge_price"><span>{{item.rechargeMoney}}</span>元</div>
              <div class="recharge_receive">
                  <b>{{item.rechargeMoney*100}}</b> 积分 
                  <span  v-if="item.awardIntegral>0"   >
                      <b style="font-size:26px;padding:0px 4px;">送</b>
                      <b>{{item.awardIntegral}} </b> 积分
                  </span>
              </div>
              <div class="recharge_average">
                  获得总计<b>{{item.rechargeMoney*100+item.awardIntegral}}</b>积分
              </div>
              <div class="recharge_economize">
                <p v-if="(item.awardIntegral/100)/item.rechargeMoney < 1 ">
                  立省<span>{{item.awardIntegral/100}}</span>元</p>
                <p v-if="(item.awardIntegral/100)/item.rechargeMoney >= 1 ">
                  <span>
                    买一送{{$comm.NoToChinese(parseInt((item.awardIntegral/100)/item.rechargeMoney))}}
                  </span>
                </p>
              </div>
              <!-- <div class="recharge_submit" :class="item.rechargeMoney<99?'tinge_submit':''"
              @click="rechargeHandle(item.rechargeMoney)">
              <div>立即充值</div>
            </div> -->
            </div>
          </template>

        </div>

      </div>



      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px" style="margin:44px 20px 0">
        <el-form-item label="支付方式:" prop="payType">
         
          <el-radio v-model="dataForm.payType" label="2" border
            style="width: 130px; position: relative; background: #f3f5ff">
            <i class="iconfont vxPayment" style="color: #06b4fd; font-size: 24px; margin-right: 10.8px">&#xe611;</i>
            <span>支付宝支付</span>
            <i class="iconfont check" style="color: #5d7cff; font-size: 16px"
              v-if="dataForm.payType === '2'">&#xe60f;</i>
          </el-radio>
          
<!--           
           <el-radio v-model="dataForm.payType" label="1" border
            style="width: 130px; position: relative; background: #f3f5ff">
            <i class="iconfont vxPayment" style="color: #06e270; font-size: 24px; margin-right: 10.8px">&#xe610;</i>
            <span>微信支付</span>
            <i class="iconfont check" style="color: #5d7cff; font-size: 16px"
              v-if="dataForm.payType === '1'">&#xe60f;</i>
          </el-radio> -->

        </el-form-item>
        <el-form-item  label="充值金额:" prop="money">
          <div v-if="forceFee">
            {{dataForm.money}}
          </div>
          <div v-else>
            <el-input-number v-model.number="dataForm.money" controls-position="right" :min="0.01" placeholder="请选择充值的金额"
              :disabled="true"
              style="width: 300px; height: 40px"  >
            </el-input-number>
          </div>
        </el-form-item>

        <div class="fast_fee" v-show="!forceFee">
          <el-button size="small" @click="fastWay(item.rechargeMoney)" v-for="(item,index) in fastFee" :key="index"
            :class="dataForm.money == item.rechargeMoney ? 'moneyAct':''" style="width:100px;margin:10px 20px;">
            {{item.rechargeMoney}}元</el-button>
        </div>
      </el-form>
    </div>

    <div v-show="num == 2 " class="qr-view">
      <div id="qrcode"></div>
    </div>

    <div v-show="num == 3 " class="result-view">
      <div class="success-pay">
        <div class="success-icon">
          <i class="el-icon-success"></i>
        </div>
        <h2 style="text-align:center">支付成功</h2>
      </div>
    </div>

    <div class="recharge-control">
      <!-- <div class="recharge-tip">
          * 积分充值所赠送的积分仅用于工具箱功能使用，不可用于礼品单使用
        </div> -->
      <el-button style="width: 150px;height:40px;" type="primary" @click="nextStep()" v-if="num == 1"
        :loading="payLoading">去支付
      </el-button>
      <el-button style="width: 150px;height:40px;" type="primary" @click="returnStep()" v-if="num == 2">返回上一步
      </el-button>
      <el-button style="width: 150px;height:40px;" type="success" @click="viewDetail()" v-if="num == 3">充值完成</el-button>
    </div>


    <div slot="footer" class="dialog-footer">
      <div class="fast_tips" v-show="!forceFee">
        <p class="fast_tips_title">积分说明：</p>
        <p v-for="(item,index) in fastFee" :key="index"><span
            :class="dataForm.money == item.rechargeMoney ? 'pitchAtc':''">{{item.discountName}}</span></p>
      </div>
      <div class="fast_tips" style="color:#ccc;font-size:12px;">
        <p>*本站所有积分消费行为，优先使用非赠送积分，请特别注意！</p>
        <!-- <p>*充值时额外赠送的积分只可用于工具箱的功能使用，暂不支持礼品单消耗，请广大用户朋友特别注意！！！</p> -->
      </div>
    </div>




  </el-dialog>
</template>

<script>
  import QRCode from "qrcodejs2";
  import {
    pollOrder,
    Recharge,
    rechargeApi,
    getValidVipInfoList
  } from "../../request/api"; //这里是引入请求

  
  export default {
    data() {
      return {
        rechargeList: [],
        visible: false,
        num: 1,
        payLoading: false,
        orderNo: "",
        timer: 1,
        dataForm: {
          payType: null,
          money: "1",
        },
        dataRule: {
          payType: [{
            required: true,
            message: "支付类型不能为空",
            trigger: "blur"
          }, ],
          money: [{
            required: true,
            message: "金额不能为空",
            trigger: "blur"
          }],
        },
        qrcode: "",
        fastFee: [],
        forceFee: false,
        selective: false,
      };
    },
    mounted() {
      this.$store.dispatch("getRechargeList").then((rechargeList) => {
          this.rechargeList = rechargeList;
          this.fastFee = rechargeList;
      });
    },
    methods: {
      // 初始化
      init(fee, forceFee, selective) {
        this.visible = true;
        this.payLoading = false;
        this.num = 1;
        this.timer = 1;
        this.dataForm.payType = "2";
        this.dataForm.money = fee;
        this.forceFee = forceFee || false;
        this.selective = selective || false
      },
      // 上一步
      returnStep() {
        this.num = 1;
        this.closeDialog();
      },
      // 去支付
      nextStep() {
        this.$nextTick(function () {
          this.$refs["dataForm"].validate((valid) => {
            if (valid) {
              this.payLoading = true;
              this.createOrder();
            }
          });
        });
      },
      viewDetail() {
        //查看订单详情
        this.$emit("freshData");
        this.closeDialog();
        this.visible = false;

      },
      // 轮询支付状态接口
      poll() {
        pollOrder({
          orderNo: this.orderNo
        }).then((data) => {
          if(data && data.code == 0){
            this.closeDialog();
            this.num = 3;
          }
           
        });
      },
      // 创建二维码
      createQRCode(payqrcode) {
        this.num = 2;
        this.$nextTick(function () {
          document.getElementById("qrcode").innerHTML = "";
          this.qrcode = new QRCode("qrcode", {
            width: 230,
            height: 230,
            text: payqrcode, //二维码内容
            colorDark: "#000000",
            colorLight: "#ffffff",
          });
          this.timer = setInterval(() => {
            this.poll();
          }, 1000);
        });
      },
      // 创建支付订单
      createOrder() {
        Recharge({
          payType: this.dataForm.payType,
          paymentAmount: this.dataForm.money,
        }).then((data) => {
          this.payLoading = false;
          this.orderNo = data.orderNo;
          this.createQRCode(data.data.codeurl);
        });
      },
      closeDialog() {
        console.log(this.qrcode == "");
        if (this.qrcode != "") {
          this.qrcode.clear(); // 清除代码
        }
        clearInterval(this.timer); //销毁定时器
      },

      fastWay(num) {
        this.dataForm.money = num
        this.$refs['dataForm'].clearValidate()
      }
    },
  };
</script>
<style lang="less" scoped>
  .qr-view,
  .result-view {
    border: 1px solid #e5e5e5;
    width: 230px;
    height: 230px;
    padding: 5px;
    margin: 20px auto 28px;
  }

  /deep/.el-dialog {
    width: 800px;
  }

  /deep/.el-dialog__title {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #111111;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: bold;
  }

  /deep/.el-form-item__label {
    font-size: 14px;
    color: #111111;
    padding-left: 28px;
  }

  /deep/.el-radio__inner {
    display: none;
  }

  /deep/.el-radio.is-bordered.is-checked {
    border-color: #5d7cff;
  }

  /deep/.el-radio__label {
    padding-left: 33px;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #5d7cff;
  }

  .vxPayment {
    position: absolute;
    top: 7px;
    left: 10px;
  }

  .check {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  /deep/.el-input>.el-input__inner {
    height: 40px;
    line-height: 40px;
  }

  /deep/.el-input-number__decrease,
  /deep/.el-input-number__increase {
    display: none;
  }

  /deep/.el-input-number.is-controls-right .el-input__inner {
    padding-right: 15px;
    text-align: left;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  .fast_fee {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .fast_tips {
    font-size: 14px;
    color: #333;
    padding: 10px 20px;

    .fast_tips_title {
      font-size: 20px;
      color: #5d7cff;
    }

    p {
      line-height: 1.8;
      text-align: left;

    }
  }

  .success-pay {
    padding: 10px;

    .success-icon {
      font-size: 120px;
      color: #009900;
      text-align: center;
    }
  }

  .pitchAtc {
    color: #409EFF;
  }

  .moneyAct {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }

  .recharge-tip {
    font-size: 14px;
    text-align: left;
    color: red;
    padding: 10px;
    text-decoration: underline;
  }


  .recharge-control {
    text-align: center;
    padding: 0px 40px;
  }








  .recharge_box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .recharge_item {
      box-shadow: 0 0 20px 0px #ccc;
      text-align: center;
      box-sizing: border-box;
      margin: 50px 0 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;

      .vip_details {
        padding: 10px 0;
        box-sizing: border-box;
        text-align: center;
        position: absolute;
        width: 100%;
        background: #ff504d;
        bottom: 100%;
        left: 0;
        margin-bottom:2px;

        .vip_name {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.6;
          text-shadow: 1px 1px #000;
        }

        .vip_authority {
          font-size: 18px;
          line-height: 1.6;
          color:#f7cb32;
          font-weight:bold;
        }
      }

      .recharge_item_content {
        width: 280px;
        height: 190px;
        padding: 20px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        .discount {
          background: #ffb100;
          width: 120px;
          height: 30px;
          -moz-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          display: block;
          position: absolute;
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          left: -34px;
          top: 11px;
        }

        .recharge_price {
          color: #a2a2a2;
          font-size: 12px;
          line-height: 45px;

          span {
            font-size: 50px;
            color: #ff3600;
            background-image: -webkit-linear-gradient(bottom, #ffb100, #ff3600);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: bold;
          }
        }

        .recharge_receive {
          font-size: 12px;
          color: #a2a2a2;
          margin-bottom: 5px;
          
          b {
            font-size: 20px;
            color: #ff3600;
          }
        }

        .recharge_average {
          font-size: 12px;
          color: #a2a2a2;
          margin-bottom: 5px;

         
        }

        .recharge_economize {
          font-size: 16px;
          margin-bottom: 5px;

          span {
            font-size: 20px;
            color: #ff3600;
          }
        }

        .recharge_submit {
          width: 110px;
          margin: 0 auto;
          color: #ffe58c;
          line-height: 35px;
          border-radius: 20px;
          font-size: 14px;
          cursor: pointer;
          background: #d01e21;
          border: 1px solid #ffe782;
          position: relative;

          div {
            animation: am1 1s linear infinite alternate 1s;
            /* 动画持续时间 匀速 运动次数(循环) alternate(往返运动)  1s(延迟)*/
          }

          &:after {
            content: '';
            width: 5px;
            height: 5px;
            background: #ffe58c;
            border-radius: 50%;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            animation: am2 1s linear infinite alternate 1s;
          }

          &:before {
            content: '';
            width: 5px;
            height: 5px;
            background: #ffe58c;
            border-radius: 50%;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            animation: am2 1s linear infinite alternate 1s;
          }
        }

        .tinge_submit {
          background: #ffb100;
          color: #fff;

          div {
            animation: none;
          }

          &:after {
            animation: none;
          }

          &:before {
            animation: none;
          }
        }

      }
    }
  }




  @media screen and (max-width: 640px) {

    /deep/.el-dialog {
      width: 100%;
    }

  }
</style>