<template>
  <div class="Loading">
    <div class="Loading-top">
      <img
        style="width: 260px; height: 200px"
        src="../../assets/imgs/Loading2.gif"
        alt=""
      />
      <div
        style="
          width: 100%;
          padding: 0 20px 0 0px;
          line-height: 20px;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        "
      >
        {{ $store.state.loadContent }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
    };
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },

    decrease() {
      this.percentage -= 10;
      if (this.percentage < 0) {
        this.percentage = 0;
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
.Loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  /* color: white; */
  .Loading-top {
    width: 400px;
    height: 300px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    text-indent: 16px;
    background-color: #fff;
  }
  .Loading-in {
    width: 400px;
    height: 100px;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
  }
}
</style>