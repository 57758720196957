<template>
    <el-dialog
      class="system-dialog"
      title="系统公告"
      :visible.sync="visible"
      width="80%"
      top="30vh"
      :modal="true"
      :close-on-click-modal="false"
      >
      <div slot="title" v-if="noticeData != null && noticeData.noticeTitle">
        <span>{{noticeData.noticeTitle}}</span>
      </div>
      <div v-if="noticeData != null" slot="default" class="notice-content">
        <div style="padding:0px  10px;" v-html="noticeHtml"></div>
        <div style="color:#666;font-size:12px;text-align:right;margin-top:20px;">{{noticeData.createTime}}</div>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="readHandle">
          <span v-if="this.noticeDataList && this.noticeDataList.length > 1">下一条</span>
          <span v-else>完成</span>
        </el-button>
      </span>
    </el-dialog>
</template>

<script>
  import {
    getNoticeList
  } from "@/request/api"

export default {
     data(){
      return{
        visible: false,
        noticeData: null,
        noticeDataList: []
      }
    },
    computed:{
      noticeHtml(){
        if(this.noticeData && this.noticeData.noticeContent){
          let content = this.noticeData.noticeContent;
          if(content){
            content = content.replace(/\n/g, "<br/>");
            content = content.replace(/\s/g, "&nbsp;");
          }
          return content;
        }
       
        return "";
      }
    },
    mounted(){
        this.fetchNoticeList();
    },
    methods: {
      noticeCountGet(id){
        //记录次数
        let countKey = "sysNotice-" + id;
        let countVal = this.$cookies.get(countKey);
        if(!countVal){
          countVal = 0;
        }else{
          countVal = parseInt(countVal);
        }

        return countVal;
      },
      noticeCountAdd(id, num){
        let countKey = "sysNotice-" + id;
        let countVal = this.noticeCountGet(id);
        this.$cookies.set(countKey, countVal + 1);
        return countVal;
      },

      fetchNoticeList(){
        if(this.$store.state.token == null){
          return;
        }

        getNoticeList().then((data) => {
          this.noticeDataList = data.list || [];
          
          //过滤累计次数
          this.noticeDataList = this.noticeDataList.filter(item => {
              if(item.dayTimes && item.dayTimes > 0){
                let countVal = this.noticeCountGet(item.noticeId);
                if(countVal >= item.dayTimes){
                  return false;
                }
              }
              
              return true;
          });

          this.readHandle();
        });

        
      },
      readHandle(){
        if(this.noticeDataList.length > 0){
          this.visible = true;
          this.noticeData = this.noticeDataList.shift();
          
          //累计次数
          this.noticeCountAdd(this.noticeData.noticeId, 1);
        }else{
          this.visible = false;
          this.noticeData = null;
        }
        
      },

    }
}
    
</script>

<style lang="less" scoped >

    .system-dialog /deep/ .el-dialog{
        max-width: 800px;
    }
   

   .notice-content{
     line-height:1.8;
   }

    
</style>