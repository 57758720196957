import router from '@/router';
import {
  Message
} from 'element-ui'


// 获取域名上的指定参数
function getUrlParam(url, paraName) {
  var url = url
  var arrObj = url.split("?");

  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;

    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");

      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
}

// 转换日期
function convertDate(date, fmt = "yyyy-MM-dd hh:mm:ss") {
  if (typeof date === 'string') {
    return date;
  }
  date = new Date(date);
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), //时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds(), //毫秒
  }

  if (!date || date == null) return null;

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  return fmt;
}

//--------日期转换-----------
//xxxx-xx-xx xx:xx:xx
function getDate(str) {
  if (str == "" || str == null) {
    return "";
  }
  if (typeof (str) == 'string') {
    str = str.replace(/-/g, '/');
  }
  var oDate = new Date(str),
    oYear = oDate.getFullYear(),
    oMonth = oDate.getMonth() + 1,
    oDay = oDate.getDate(),
    oHour = oDate.getHours(),
    oMin = oDate.getMinutes(),
    oSen = oDate.getSeconds(),
    oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) +
    ':' + getzf(oSen); //最后拼接时间
  return oTime;
}
//补0操作
function getzf(num) {
  if (parseInt(num) < 10) {
    num = '0' + num;
  }
  return num;
}

//---------点击复制---------------
function copy(val) {
  var input = document.createElement("textarea");
  input.style.position = "absolute";
  input.style.top = "-100%";
  input.style.left = "-100%";
  input.value = val;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, input.value.length);
  let copyResult = document.execCommand('Copy');
  document.body.removeChild(input);
  if(copyResult){
    Message.success('复制成功');
  }else{
    Message.error('复制失败');
  }
  
}

// 获取域名上所有
function UrlParamHash(url) {
  var params = [],
    h;
  var hash = url.slice(url.indexOf("?") + 1).split("&");
  for (var i = 0; i < hash.length; i++) {
    h = hash[i].split("=");
    params.push(h[0]);
    params[h[0]] = h[1];
  }
  return params;
}

// 判断域名是否正确
function isUrl(str) {
  var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i');
  return v.test(str);
}

// 验证手机号
function isPhone(phone) {
  var reg = /^1[3-9]\d{9}$/;
  if (reg.test(phone)) {
    return true;
  } else {
    return false;
  }
}

//阿拉伯数字转中文数字
function NoToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
      alert("Number is wrong!");
      return "Number is wrong!";
  }
  var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
  var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
  var a = ("" + num).replace(/(^0*)/g, "").split("."),
      k = 0,
      re = "";
  for (var i = a[0].length - 1; i >= 0; i--) {
      switch (k) {
          case 0:
              re = BB[7] + re;
              break;
          case 4:
              if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                  re = BB[4] + re;
              break;
          case 8:
              re = BB[5] + re;
              BB[7] = BB[5];
              k = 0;
              break;
      }
      if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
      if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
      k++;
  }
  if (a.length > 1) //加上小数部分(如果有小数部分) 
  {
      re += BB[6];
      for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  return re;
}

function keepTwoDecimalFull(num) {
  var result = parseFloat(num);
  if (isNaN(result)) {
  return false;
  }
  result = Math.round(num * 100) / 100;
  var s_x = result.toString();
  var pos_decimal = s_x.indexOf('.');
  if (pos_decimal < 0) {
  pos_decimal = s_x.length;
  s_x += '.';
  }
  while (s_x.length <= pos_decimal + 2) {
  s_x += '0';
  }
  return s_x;
 }


 // 下划线转换驼峰
function toHump(name) {
  if(!name){
    return name;
  }
  
  return new String(name).replace(/\_(\w)/g, function(all, letter){
      return letter.toUpperCase();
  });
}

// 驼峰转换下划线
function toLine(name) {
  if(!name){
    return name;
  }
  return name.replace(/([A-Z])/g,"_$1").toLowerCase();
}


/**
 * 
 * @returns 返回主机地址
 */
function getAbsoluteUrl(path){
  if(!path){
    path = "/";
  }
  
  if(path.indexOf("/") !=  0){
      path = "/" + path;
  }

  let baseUrl = router.options.base.replace(/\/$/,"");
  return location.protocol + "//" + location.host + baseUrl + path;
}


/**
 * 获取相对地址
 */
function getRelativePath(fullPath){
  if(!fullPath){
    return fullPath;
  }
  let baseUrl = router.options.base.replace(/\/$/,"");
  let realPath = fullPath.replace(new RegExp("^" + baseUrl), "");
  return realPath;
}



export default {
  getUrlParam,
  copy,
  isPhone,
  getDate,
  convertDate,
  UrlParamHash,
  isUrl,
  NoToChinese,
  keepTwoDecimalFull,
  toHump,
  toLine,
  getAbsoluteUrl,
  getRelativePath
}