<template>
  <div id="app">
    <router-view />
    <MsgNotice></MsgNotice>
    <SystemNotice></SystemNotice>
  </div>
</template>

<script>

import MsgNotice from "@/components/public/MsgNotice.vue";
import SystemNotice from "@/components/public/SystemNotice.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    MsgNotice,
    SystemNotice,
  },
  created() {
    //防止异步操作延迟，预先使用缓存
    let cacheData = sessionStorage.getItem("modelIntegral");
    if (cacheData != null) {
      try {
        let integralList = JSON.parse(cacheData);
        if (integralList instanceof Array) {
          this.$store.commit("modelIntegralMap", integralList);
        }
      } catch (e) {
        console.log("load cache error:" + e);
      }
    }

    this.$store.dispatch("getSiteInfo");
    this.$store.dispatch("getInterfacePrice");
  },
  methods: {},
};
</script>

<style less>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background: #f3f8ff;
  font-family: PingFangSC-Regular !important;
}

#app {
  height: 100%;
  /* min-height: 100%; */
  /* overflow: hidden; */
}

/* 头尾主题颜色 */
.headToTailColor {
  background-color: #1b84ff !important;
}

.btn {
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 1px;
  width: 100px;
  height: 36px;
  margin: 22px 0;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.btn:hover {
  background: #3b9bff !important;
}


.el-button--primary {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}

.el-button {
  /* height: 36px;
    line-height: 36px;
    padding: 0 20px !important; */
}

/* 每个分页的大标题 */
.headline {
  font-size: 24px;
  color: #111111;
  letter-spacing: 0;
  width: 100%;
  height: 53px;
}

/* 每个分页的内容 */
.banner {
  width: 70%;
  margin: 28px auto;
  /* background-color: #ffffff; */
  /* border-radius: 2px; */
  /* padding: 30px 40px; */
  box-sizing: border-box;
}

/* 查询后的内容样式 */
.bannerContent {
  width: 100%;
  /* padding: 0 50px 100px; */
  box-sizing: border-box;
}

/* 表格头部样式 */
.tableTitle {
  text-align: center;
  height: 50px;
  line-height: 50px;
  background-color: rgb(227, 236, 255);
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  font-weight: bold;
}

@font-face {
  font-family: "iconfont";
  /* project id 2499478 */
  src: url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.eot");
  src: url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.woff") format("woff"),
    url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_2499478_plz1lo78wr8.svg#iconfont") format("svg");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.danger,
.success,
.info,
.warning {
  border-radius: 13.5px;
  padding: 3px 13px;
  height: 28px;
  line-height: 28px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
}

.success {
  background: #ccffe5;
  color: #00ca60;
}

.danger {
  background: #ffeeee;
  color: #f70000;
}

.info {
  background-color: #f4f4f4;
  color: #424242;
}

.warning {
  background-color: #fdf6ec;
  color: #e6a23c;
}


.pagination{
  text-align: right;
  margin: 20px 50px 20px 0;
}



@media screen and (max-width: 640px){
  .banner{
    width:100%;
  }

  .pagination{
    margin:0px;
    padding:8px;
    overflow: auto;
    text-align: center;
  }
}

</style>