<template>
    <div>
        <!-- 漂浮导航 -->
        <div class="float">
            <!-- <div class="floatDIV">
                <div class="floatIn" @click="toRoute('/SearchListings')">数据鱼</div>
            </div> -->
            <div class="floatDIV">
                <div class="floatIn" @click="inviteFriend">邀请好友</div>
            </div>
            <div class="floatDIV">
                <div class="floatIn">视频教学</div>
            </div>
            <div class="floatDIV" @click="rechargeHandle()">
                <div class="floatIn">充值购买</div>
            </div>

            <div class="floatDIV" >
                <el-popover placement="left" width="200" trigger="hover">
                    <div style="text-align: center;">
                        <p style="margin:10px 0;">客服微信</p>
                        <el-image style="width: 200px; height: 200px" :src="serviceQr" fit="fit">
                            <span slot="error"
                                style="line-height: 200px;color: #f72e2e;font-size: 14px;">请登录后查看您的专属客服</span>
                        </el-image>
                    </div>
                    <div class="floatIn" style="border:0;" slot="reference">联系客服</div>
                </el-popover>
            </div>
            <div class="floatDIV" @click="suggestPopup = true">
                <div class="floatIn">宝贵建议</div>
            </div>

            <div class="float_hover">工具箱</div>
        </div>

        <!-- 充值弹窗 -->
        <recharge v-if="rechargeVisible" ref="recharge"></recharge>

        <!-- 建议弹窗 -->
        <suggest :pupopBus="suggestPopup" @suggestBus="suggeastClose"></suggest>
    </div>

</template>

<script>
    import suggest from "@/components/public/suggest.vue"
    import Recharge from "@/components/public/recharge.vue";
    export default {
        data() {
            return {
                suggestPopup: false,
                rechargeVisible: false, //是否显示充值pup
            }
        },
        components: {
            Recharge,
            suggest
        },
        mounted(){
            
        },
        methods: {
            // 邀请好友
            inviteFriend() {
                this.toRoute('/personalCenter');
            },
            // 充值
            rechargeHandle() {
                this.rechargeVisible = true;
                this.$nextTick(() => {
                    this.$refs.recharge.init();
                });
            },

            // 关闭建议
            suggeastClose(bool) {
                this.suggestPopup = bool
            },
        },
        computed:{
            serviceQr(){
                return this.$store.state.serviceQr;
            }
        }
    }
</script>

<style lang="less" scoped>
    /* 漂浮导航 */
    .float {
        position: fixed;
        width: 60px;
        // background-color: #fff;
        border-radius: 4px;
        bottom: 50px;
        right:-105px;
        padding-right:45px;
        z-index: 99999;
        transition: 0.2s all ease;

        .floatDIV {
            width: 100%;
            color: #333333;
            border-bottom: 1px solid #e5e5e5;

            .floatIn {
                font-size: 14px;
                letter-spacing: 0;
                text-align: center;
                height: 70px;
                padding: 15px 16px;
                box-sizing: border-box;
                cursor: pointer;
                background: #f3f3f3;
            }
        }

        .floatDIV:hover {
            background-color: #5d7cff;
            color: #409eff;
        }

        .float_hover{
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            background: #409eff;
            text-align: center;
            padding: 20px 10px;
            font-size: 14px;
            color: #fff;
        }

        &:hover{
            right: 0;
        }
    }
</style>