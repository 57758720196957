<template>
  <div>
    <!-- 漂浮导航 -->
    <div class="tool-container">
      <transition name="el-fade-in">
        <div class="tool-menu" v-show="visible">
          <div class="tool-item">
            <el-popover
              placement="left"
              width="200"
              trigger="hover"
              popper-class="service-popover"
            >
              <div style="text-align: center">
                <p style="margin: 10px 0">客服微信</p>
                <el-image
                  style="width: 200px; height: 200px"
                  :src="serviceQr"
                  fit="fit"
                  v-if="serviceQr"
                >
                </el-image>
              </div>
              <div class="floatIn" style="border: 0" slot="reference">
                联系客服
              </div>
            </el-popover>
          </div>

          <div class="tool-item" @click="suggestPopup = true">
            <div class="floatIn">宝贵建议</div>
          </div>

          <div class="tool-item"  v-if="shareUrl">
            <div class="floatIn" @click="copyWay(shareUrl)">我要推广</div>
          </div>
          <!-- <div class="tool-item">
                    <div class="floatIn" @click="videoStudy">视频教学</div>
                </div> -->
          <div class="tool-item" @click="rechargeHandle()">
            <div class="floatIn">积分特惠</div>
          </div>
        </div>
      </transition>

      <div class="tool-btn" @click="switchMenu">
        <span v-show="!visible">客服</span>
        <span v-show="visible" style="font-size: 30px">
          <i class="el-icon-close"></i>
        </span>
      </div>
    </div>

    <!-- 充值弹窗 -->
    <recharge v-if="rechargeVisible" ref="recharge"></recharge>

    <!-- 建议弹窗 -->
    <suggest :pupopBus="suggestPopup" @suggestBus="suggeastClose"></suggest>
  </div>
</template>

<script>
import suggest from "@/components/public/suggest.vue";
import Recharge from "@/components/public/recharge.vue";

export default {
  data() {
    return {
      visible: false,
      suggestPopup: false,
      rechargeVisible: false, //是否显示充值pup
    };
  },
  components: {
    Recharge,
    suggest,
  },
  mounted() {},
  methods: {
    // 复制
    copyWay(val) {
      this.$comm.copy(val + "\n" + this.$store.state.copySuffix);
    },
    // 充值
    rechargeHandle() {
      this.rechargeVisible = true;
      this.$nextTick(() => {
        this.$refs.recharge.init();
      });
    },

    // 关闭建议
    suggeastClose(bool) {
      this.suggestPopup = bool;
    },

    switchMenu() {
      if (this.visible) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    videoStudy() {
      this.$message.success("敬请期待");
    },
  },
  computed: {
    shareUrl() {
      if (this.$store.state.shareObj) {
        return this.$store.state.shareObj.shref;
      }
      return "";
    },
    serviceQr() {
      return this.$store.state.serviceQr;
    },
  },
};
</script>

<style lang="less" scoped>
/* 漂浮导航 */
.tool-container {
  position: absolute;
  right: 60px;
  bottom: 40px;
  z-index: 99999;
  font-size: 14px;
}

.tool-btn {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 0px;
  box-sizing: border-box;
  background: #409eff;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.tool-btn:hover{
    background-color:#409eee;
    font-size:18px;
}

.tool-menu {
  position: absolute;
  bottom: 100%;
  right: 0px;
  min-width: 240px;
  background: white;
  border: solid 1px #eee;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);

  .tool-item {
    border-bottom: 1px solid #efefef;
    padding: 10px;
    color: #333333;
    cursor: pointer;
  }

  .tool-item:hover{
    color:#222;
    background-color:#efefef;
  }
}

.el-popper.service-popover {
  margin-right: 30px !important;
}


@media screen and (max-width: 640px) {
  .tool-container {
    display: none;
  }

}

</style>
