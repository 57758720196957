<template>
    <div class="auto-container" >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            
        },
        data() {
            return {
                
            }
        },
        mounted() {},
        methods: {

        },
        watch: {
          
        }
    }
</script>

<style lang="less" scoped>
    .auto-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
  
  @media screen and (max-width: 640px){

   .auto-container{
        flex-direction: column;
   }
  }
</style>