import Vue from 'vue'
import Router from 'vue-router'
import qs from "qs";
import home from '@/views/home' //导入主页
import { behaviorTick } from '@/request/api';

Vue.use(Router)


var router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [{
    path: '/',
    redirect: '/page',
    component: home,
    children: [
      { //首页
        path: '/page',
        name: 'page',
        component: () => import('@/views/page.vue'),
      },
      { //免责声明
        path: '/statement',
        name: 'statement',
        component: () => import('@/components/statement.vue'),
      },
      // { //充值活动
      //   path: '/recharge',
      //   name: 'recharge',
      //   component: () => import('@/components/activityPage/RechargePage.vue'),
      // },
      { //个人中心
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/components/personalCenter'),
      },
      { //类目查询路由
        path: '/CategoriesQuery',
        name: 'CategoriesQuery',
        component: () => import('@/components/CommodityPerspective/CategoriesQuery')
      },
      { //属性查询路由
        path: '/AttributeQuery',
        name: 'AttributeQuery',
        component: () => import('@/components/CommodityPerspective/AttributeQuery')
      },
      { //商品SKU占比分析路由
        path: '/CommodityEvaluation',
        name: 'CommodityEvaluation',
        component: () => import('@/components/CommodityPerspective/CommodityEvaluation')
      },
      { //商品SKU占比分析路由
        path: '/CommodityEvaluationV2',
        name: 'CommodityEvaluationV2',
        component: () => import('@/components/CommodityPerspective/CommodityEvaluationV2')
      },
      { //商品评价路由
        path: '/SkuAccountedFor',
        name: 'SkuAccountedFor',
        component: () => import('@/components/CommodityPerspective/SkuAccountedFor')
      },
      { //商品卖家秀路由
        path: '/SellerShow',
        name: 'SellerShow',
        component: () => import('@/components/CommodityPerspective/SellerShow'),
      },
      { //问大家/评价/买家秀路由
        path: '/CommodityAnswer',
        name: 'CommodityAnswer',
        component: () => import('@/components/CommodityPerspective/CommodityAnswer'),
      },
      { //查标签/权重路由
        path: '/LabelWeight',
        name: 'LabelWeight',
        component: () => import('@/components/InDepthInspection/LabelWeight'),
      },
      { // 历史价格
        path: '/GoodsHistoryPrice',
        name: 'GoodsHistoryPrice',
        component: () => import('@/components/CommodityPerspective/GoodsHistoryPrice'),
      },
      { //一键查旺旺路由
        path: '/QueryWant',
        name: 'QueryWant',
        component: () => import('@/components/InDepthInspection/QueryWant'),
      },
      { //旺旺打标路由
        path: '/WantWantMarking',
        name: 'WantWantMarking',
        component: () => import('@/components/InDepthInspection/WantWantMarking'),
      },
      { //在线指数还原路由
        path: '/OnLineExponentialReduction',
        name: 'OnLineExponentialReduction',
        component: () => import('@/components/InDepthInspection/OnLineExponentialReduction'),
      },
      { //DSR智能计算器路由
        path: '/DSRcalculator',
        name: 'DSRcalculator',
        component: () => import('@/components/InDepthInspection/DSRcalculator'),
      },
      { //淘客订单查询路由
        path: '/TaokeOrderInquiry',
        name: 'TaokeOrderInquiry',
        component: () => import('@/components/InDepthInspection/TaokeOrderInquiry'),
      },
      { //多功能卡首屏路由
        path: '/multifunctionCard',
        name: 'multifunctionCard',
        component: () => import('@/components/CardFirstScreen/multifunction'),
      },
      { //关键字卡首屏路由
        path: '/keywordCard',
        name: 'keywordCard',
        component: () => import('@/components/CardFirstScreen/keywordCard'),
      },
      { //淘口令路由
        path: '/NewPassword',
        name: 'NewPassword',
        component: () => import('@/components/InDepthInspection/NewPassword'),
      },
      { //关键字查排名路由
        path: '/KeywordRankingBak',
        name: 'KeywordRankingBak',
        component: () => import('@/components/RankingAndCompetition/KeywordRankingBak'),
      },
      { //关键字查排名路由
        path: '/KeywordRanking',
        name: 'KeywordRanking',
        component: () => import('@/components/RankingAndCompetition/KeywordRanking'),
      },
      { //关键字查排名路由
        path: '/KeywordRankingV1',
        name: 'KeywordRankingV1',
        component: () => import('@/components/RankingAndCompetition/KeywordRankingV1'),
      },
      { //关键字查排名路由
        path: '/KeywordRankingV2',
        name: 'KeywordRankingV2',
        component: () => import('@/components/RankingAndCompetition/KeywordRankingV2'),
      },
      { //相似/同款分析路由
        path: '/SameItemAnalysis',
        name: 'SameItemAnalysis',
        component: () => import('@/components/RankingAndCompetition/SameItemAnalysis'),
      },
      // 搜索列表
      {
        path: '/SearchListings',
        name: '/SearchListings',
        component: () => import('@/components/shujuyu/SearchListings')
      },
      {
        path: '/TbRoarWord',
        name: '/TbRoarWord',
        component: () => import('@/components/shujuyu/tbRoarWord')
      },
      {
        path: '/SubwayHotWord',
        name: '/SubwayHotWord',
        component: () => import('@/components/shujuyu/subwayHotWord')
      },

      // 流量网
      // {
      //   path: '/manage/taobao',
      //   name: 'manageTaobao',
      //   component: resolve => require(['@/components/flow/taobao'], resolve),
      // },
      // {
      //   path: '/manage/taobaoFav',
      //   name: 'manageTaobaoFav',
      //   component: resolve => require(['@/components/flow/taobao'], resolve),
      // },
      // {
      //   path: '/manage/taobaoAdd',
      //   name: 'manageTaobaoAdd',
      //   component: resolve => require(['@/components/flow/taobao'], resolve),
      // },
      // {
      //   path: '/manage/jingdong',
      //   name: 'manageJingdong',
      //   component: resolve => require(['@/components/flow/jingdong'], resolve),
      // },
      // {
      //   path: '/manage/pinduoduo',
      //   name: 'managePinduoduo',
      //   component: resolve => require(['@/components/flow/pinduoduo'], resolve),
      // },
      // {
      //   path: '/manage/taskNew',
      //   name: 'manageTaskNew',
      //   component: resolve => require(['@/components/flow/taskNew'], resolve),
      // },
      // {
      //   path: '/manage/taskNewLegacy',
      //   name: 'manageTaskNewLegacy',
      //   component: resolve => require(['@/components/flow/taskNewLegacy'], resolve),
      // },
      // 插件下载页
      {
        path: "/plugin",
        name: "plugin",
        component: () => import("@/components/plugin/install.vue")
      },
      //自动排单表介绍
      {
        path: "/autoSchedule",
        name: "autoSchedule",
        component: () => import("@/components/plugin/autoSchedule.vue")
      },
      // 推广赚钱
      {
        path: "/extendmoney",
        name: "extendmoney",
        component: () => import("@/components/extension/extendmoney.vue")
      },
      // 分站代理
      {
        path: "/cooperation",
        name: "cooperation",
        component: () => import("@/components/extension/cooperation.vue")
      },
      // 深度验号
      {
        path: '/ValidateAccount',
        name: '/ValidateAccount',
        component: () => import('@/components/InDepthInspection/ValidateAccount')
      },

      // 深度验号  步骤
      {
        path: '/ValidateAccount_step',
        name: '/ValidateAccount_step',
        component: () => import('@/components/InDepthInspection/ValidateAccount_step')
      },
      // 深度验号  授权
      {
        path: '/TBauthorization',
        name: '/TBauthorization',
        component: () => import('@/components/InDepthInspection/h5TBauthorization')
      },
      { //商品入池检测
        path: '/ItemCheckInPool',
        name: 'ItemCheckInPool',
        component: () => import('@/components/CommodityPerspective/ItemCheckInPool'),
      },
      { //淘宝下拉词
        path: '/TbRecommendKeys',
        name: 'TbRecommendKeys',
        component: () => import('@/components/CommodityPerspective/TbRecommendKeys'),
      },
      { //标题权重分析
        path: '/TbTitleSplit',
        name: 'TbTitleSplit',
        component: () => import('@/components/CommodityPerspective/TbTitleSplit'),
      },
    ]
  }, { //注册页面
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404未找到'
    }
  },

  ]
})


// 解决vue的router新版反复点击去同一个路由时报错的问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = Router.prototype.replace
originalReplace.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


//添加页面浏览行为
router.afterEach((to, from) => {
  let routePath = to.fullPath;
  let transDesc = from.fullPath + "  =>  " + to.fullPath;
  behaviorTick({ routePath: routePath, transDesc: transDesc });
});



export default router;