<template>
    <div class="float_box"  v-if="visible">
        <div class="float_title">
            <!-- <span>{{title}}</span> -->
            <div class="float_jifen" @click="toRoute('/recharge')" v-if="unfold" >
                <img src="@/assets/imgs/icon-integral.png" alt=""><span>积分特惠</span>
            </div>
            <div class="control-btn" @click="toggleUnfold">
                <i class="el-icon-d-arrow-left" v-if="unfold"></i>
                <i class="el-icon-d-arrow-right" v-else ></i>
            </div>
        </div>
        <el-scrollbar style="height:calc(100% - 60px)"  v-if="unfold" >
        <div class="float_list">
            <div v-for="(item,i) in data" :key="i" class="float_item">
                 <div  class="float_c_item"
                    @click="toRoute(item.url)">
                    <img :src="item.funcIcon" alt="" class="float_c_item_img">
                    <span class="float_c_item_name">{{item.name}}</span>
                    <span v-if="item.limit" ><el-tag size="mini">限免</el-tag></span>
                    <span v-else-if="item.new" ><el-tag size="mini" effect="dark" color="#ff5601" style="border-color:#ff5601;">new</el-tag></span>
                    <span v-else-if="getIntegral(item.modelCode)[0]>0"><el-tag v-if="false" type="warning" size="mini">每次{{getIntegral(item.modelCode)[0]}}积分</el-tag></span>
                    <span v-else>
                        <!-- <el-tag type="danger" size="mini">免费</el-tag> -->
                    </span>
                </div>
            </div>
        </div>

        </el-scrollbar>
        <div class="float_bottom"  v-if="unfold" >
                    <router-link tag="a" target="_blank" :to="{path:'/statement'}"  class="liability-link">免责声明</router-link>
        </div>
    </div>
</template>

<script>
    import menuList from "@/router/funcMenuConfig.js";
    export default {
        data() {
            return {
                data: menuList,
                visible: true,
                unfold: true,
            }
        },
        mounted() {
            this.checkVisible();
        },
        methods: {
            // 获取积分
            getIntegral(speed) {
                let spendNum = [];
                if (speed) {

                    let spendArr = speed.split(",")
                    let spendNumArr = [];
                    if(this.$store.state.modelIntegralMap != null){
                        spendArr.map(s => {
                            spendNumArr.push(this.$store.state.modelIntegralMap.get(s))
                        })
                    }
                    

                    if (spendNumArr.length > 1) {
                        let sumValue = spendNumArr.reduce((sum, number) => {
                            return sum + number;
                        })
                        spendNum.push(sumValue)
                    } else {
                        spendNum = spendNumArr
                    }
                }
                return spendNum
            },
            checkVisible() {
                let path = this.$route.path;
                let pathArr = ["/page", "/cooperation"];
                if (pathArr.includes(path)) {
                    this.visible = false;
                } else {
                    this.visible = true;
                }
            },
            toggleUnfold(){
                this.unfold = !this.unfold;
            }
        },
        computed:{
            title(){
                return this.$store.state.title;
            }
        },
        watch:{
            $route(){
                this.checkVisible();
            }
        }
    }
</script>

<style lang="less" scoped>
    .float_box {
        background: #fff;
        padding: 20px 10px;
        box-shadow:1px 0px 6px rgba(0,0,0,0.2);
        border-radius:6px;
        flex-shrink: 0;
        position: fixed;
        left: 0;
        top: 120px;
        bottom: 40px;
        z-index: 99;


        .float_title {
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            .float_jifen{
                color: #ff9300;
                font-size: 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                img{
                    margin-right:5px;
                }
            }

            .control-btn{
                padding:0 10px;
                cursor: pointer;
            }

            .control-btn:active{
                background-color: #eee;
            }

        }

        .float_list {
            padding: 0 10px;
            .float_item {

                .float_c_item {
                    display: flex;
                    height: 30px;
                    line-height: 1.2;
                    cursor: pointer;
                    margin: 8px 0;
                    align-items: center;

                    &:hover {
                        .float_c_item_name {
                            color: #409eff;
                        }
                    }

                    .float_c_item_img {
                        text-align: center;
                        width: 33px;
                        height: 33px;
                        margin-right: 8px;
                    }

                    .float_c_item_name {
                        font-size: 16px;
                        color: black;
                        flex: 1;
                    }
                }
            }

        }
                    .float_bottom{
                padding: 5px 20px;
                font-weight: bold;
            }
    }
/deep/.el-scrollbar__bar.is-vertical{
}
/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
/deep/.el-scrollbar__bar.is-vertical>div{
}
/deep/.el-scrollbar__thumb{
    background-color: rgba(64, 158, 255, 0.3);
}



@media screen and (max-width: 640px) {
    .float_box{
        display: none;
    }

}
</style>