<template>
  <div class="Loading">
    <div class="Loading-top">
      <!-- <img
        style="width: 260px; height: 200px"
        src="../assets/img/Loading2.gif"
        alt=""
      />
      <div
        style="
          width: 100%;
          padding: 0 20px 0 0px;
          line-height: 20px;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        "
      >
        {{ $store.state.common.fishLoadContent }}
      </div> -->
      <div style="text-align: center">
        <el-progress type="circle" :percentage="percentage"></el-progress>
      </div>

      <p style="text-indent: 25px;text-align:center;">
        正 在 {{$store.state.fishLoadContent=="添加"?"":"加 载"}}
        <span style="color: #45c2b5"
          >{{
            $store.state.fishLoadContent
          }}</span
        >
        相 关 数 据 , 请 耐 心 等 候 
      </p>
      
      <p  class="loading-notice">请注意当天首次加载某项数据将需要较长时间</p>

      <div class="trace-msg"  >
        <span v-if="$store.state.fishTraceInfo && $store.state.fishTraceInfo.traceMsg">
            <i class="el-icon-loading"></i> {{$store.state.fishTraceInfo.traceMsg}}
        </span>
        
      </div>

      <!-- <el-progress type="circle" :percentage="25"></el-progress>
      <el-progress
        type="circle"
        :percentage="100"
        status="success"
      ></el-progress>
      <el-progress
        type="circle"
        :percentage="70"
        status="warning"
      ></el-progress>
      <el-progress
        type="circle"
        :percentage="50"
        status="exception"
      ></el-progress> -->
    </div>
    <!-- -->
    <!-- <el-progress
      class="Loading-in"
      :text-inside="true"
      :stroke-width="26"
      :percentage="percentage"
    ></el-progress> -->
  </div>
</template>

<script>

import {
  getTraceInfo
} from "@/request/api"; //这里是引入请求


export default {
  data() {
    return {
      percentage: 0,
    };
  },
  mounted() {
    let timer = setInterval(() => {
      if (this.percentage >= 100 || !this.$store.state.fishFlag) {
        //当用户百分之百时关闭
        this.$store.commit("alterFishFlag", false);
        this.$store.commit("alterTraceInfo", null);
        this.percentage = 0;
        //清除定时器
        clearInterval(timer);
      }

      if (this.percentage < 99) {
        //正常情况下只+1
        this.percentage++;
      }

      //获取跟踪状态
      if(this.$store.state.fishTraceId){
          getTraceInfo({traceId:this.$store.state.fishTraceId}).then((data) => {
            this.$store.commit("alterTraceInfo", data.data);
          })
      }
    }, this.$store.state.fishLoadNumber / 100);
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },

    decrease() {
      this.percentage -= 10;
      if (this.percentage < 0) {
        this.percentage = 0;
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
.Loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* color: white; */
  .Loading-top {
    width: 80%;
    height: 60%;
    max-width: 480px;
    min-height: 320px;
    max-height: 380px;
    /* text-align: center; */
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-weight: bold;
    /* text-indent: 16px; */
    background-color: #fff;
    padding: 0;
    padding: 40px 40px 20px;
    box-sizing: border-box;
  }
  /* .Loading-in {
    width: 400px;
    height: 100px;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
  } */


  .loading-notice{
    text-align: center;
    font-size:12px;
    color:red;
    padding-top:10px;
  }

    
  .trace-msg{

    margin:10px;
    line-height:2;
    color:#666;
    text-align:center;
    font-size:18px;
    font-weight:normal;
    border:solid 1px #eee;
    border-radius:6px;

  }

}

</style>