//视频数据
export const videoData = {
    // "/QueryWant":{
    //     name:"一键查旺旺",
    //     url:"https://video.shujufish.com/introduction/chawangwang.mp4"
    // },
    // "/SearchListings":{
    //     name:"蓝海词查询",
    //     url:"https://video.shujufish.com/introduction/bluewordssearchnew.mp4"
    // },
    // "/CategoriesQuery":{
    //     name:"类目查询",
    //     url:"https://video.shujufish.com/introduction/categoryQuery.mp4"
    // },
    // "/WantWantMarking":{
    //     name:"旺旺打标",
    //     url:"https://video.shujufish.com/introduction/wangwangMarking.mp4"
    // },
    // "/keywordCard":{
    //     name:"关键词卡首品",
    //     url:"https://video.shujufish.com/introduction/keywordsputinfront.mp4"
    // },
    // "/KeywordRanking":{
    //     name:"商品排名",
    //     url:"https://video.shujufish.com/introduction/itemsRanknew.mp4"
    // },
    // "/ValidateAccount":{
    //     name:"深度验号",
    //     url:"https://video.shujufish.com/introduction/deepcheckid.mp4"
    // },
    // "/JingPinAnalyse":{
    //     name:"竞品分析",
    //     url:"https://video.shujufish.com/introduction/jingpinanalysis.mp4"
    // },
    // "/DSRcalculator":{
    //     name:"DSR智能计算",
    //     url:"https://video.shujufish.com/introduction/dsrnew.mp4"
    // },
    // "/OnLineExponentialReduction":{
    //     name:"指数还原",
    //     url:"https://video.shujufish.com/introduction/oneclikdata.mp4"
    // },
    // "/AttributeQuery":{
    //     name:"属性查询",
    //     url:"https://video.shujufish.com/introduction/shuxingchaxun.mp4"
    // },
    // "/SkuAccountedFor":{
    //     name:"SKU占比分析",
    //     url:"https://video.shujufish.com/introduction/SKURatioAnalysis.mp4"
    // },
    // "/CommodityAnswer":{
    //     name:"问大家",
    //     url:"https://video.shujufish.com/introduction/wendajia.mp4"
    // },
    // "/TaokeOrderInquiry":{
    //     name:"淘客订单查询",
    //     url:"https://video.shujufish.com/introduction/taokedingdan.mp4"
    // },
    // "/NewPassword":{
    //     name:"淘口令",
    //     url:"https://video.shujufish.com/introduction/taokouling.mp4"
    // },
};

