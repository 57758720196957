<template>
    <el-dialog
      class="notice-dialog"
      title="系统消息"
      :visible.sync="noticeDialogPop"
      width="80%"
      top="30vh"
      :modal="true"
      :close-on-click-modal="false"
      :before-close="noticeBeforeClose"
      >
      <div v-if="noticeData != null" slot="default" class="notice-content" >
        <div style="text-indent: 2rem;">{{noticeData.content}}</div>
        <div style="color:#666;font-size:12px;text-align:right;margin-top:20px;">{{noticeData.createTime}}</div>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="closeNoticeDialog">已读</el-button>
      </span>
    </el-dialog>
</template>

<script>
    import {
    getUnreadList,
    readMsg
  } from "@/request/api"


export default {
     data(){
      return{
        noticeData: null,
        noticeDialogPop: false,
        unreadTimeval: null,
      }
    },
    created(){
        let vue = this;
        this.unreadTimeval = setInterval(() => {
            //定时检查
            if(vue.noticeData == null){
                vue.noticeDetect();
            }
            
        },30000);

        vue.noticeDetect();

    },
    methods: {
        noticeDetect(){
        if(this.$store.state.token == null){
          return;
        }

        var vue = this;
        getUnreadList().then(data=>{
          if(data.code == 401){
              clearInterval(this.unreadTimeval);
          }

          let noticeData = null;
          if(data.data.length != 0){
            for(let i =0 ; i < data.data.length; i++){
              let itemObj =  data.data[i];
              if(itemObj.noticeStatus == 1){
                noticeData = itemObj;
                break;
              }
            }

            if(noticeData == null){
              //没有可用消息
              return;
            }
            
            if(noticeData.noticeType == 0 || noticeData.noticeType == null){
              let textContent = '<div style="color:red;">'+noticeData.content+'</div>'
                + '<div style="color:#666;font-size:12px;text-align:right;">'+noticeData.createTime+'</div>';
              this.$notify({
                title: '系统消息',
                dangerouslyUseHTMLString: true,
                message: textContent,
                position: 'bottom-right',
                duration: 20000,
                onClose(){
                  //读取消息
                  if(noticeData != null){
                    readMsg({msgId:noticeData.msgId}).then((data) => {
                        vue.noticeDetect();
                    });
                  }
                  
                }
              });
            }else if(noticeData.noticeType == 1){
                this.noticeDialogPop = true
            }


          }

          //更新状态
          this.noticeData = noticeData;

        }).catch(() => {
            clearInterval(this.unreadTimeval);
        });
        
      },
      noticeBeforeClose(done){
        //读取消息
        let vue = this;
        if(vue.noticeData != null){
          readMsg({msgId:vue.noticeData.msgId}).then((data) => {
              vue.noticeDetect();
          });
        }

        done && done();
        
      },
      closeNoticeDialog(id){
        //关闭窗口
        this.noticeDialogPop = false;
        this.noticeBeforeClose();
      },
    }
}
    
</script>

<style lang="less" scoped >

    .notice-dialog /deep/ .el-dialog{
      max-width: 800px;
    }

    .notice-content{
     line-height:1.8;
   }
   
    
</style>