<template>
    <div class="box-card">
        <div class="box_flex">
            <div class="lbox">
                <span class="title">{{boxTitle}}</span>
                <span class="desc" v-if="descMess">{{descMess}}</span>
                <span class="video_teach"  @click.stop="openVideo($route.path)"  v-if="isExist($route.path)">
                    <i class="el-icon-video-play"></i>视频教学
                </span>
            </div>
            <div class="rbox" v-if="spendNum.length>0">
                <span v-for="(item,index) in spendNum" :key="index">
                    <span v-if="index > 0"> | </span>

                    {{columnTitle[index]}}使用一次扣除 {{item || '?'}} 积分

                </span>
            </div>
        </div>
        <div class="box-ext">
            <div class="box-tip" v-if="liability">
                <p>免责声明：</p>
                <p>{{liability}}</p>
            </div>
            <div class="video-view" :style="videoViewStyle" @click="openVideo($route.path)"  v-if="isExist($route.path)"> 
                <div class="video-play">
                    <el-image :src="videoIcon"></el-image>
                </div>
                <div class="video-desc">视频教学</div>
            </div>
        </div>
        <slot></slot>

        <el-dialog :title="videoTitle" :visible.sync="dialogPlay" width="50%" @close="closeDialog">
            <video :src="videoUrl" controls autoplay class="video" ref="dialogVideo" width="100%"></video>
        </el-dialog>

    </div>
</template>

<script>
    import {videoData} from "@/utils/videoData";

    export default {
        props: {
            title: {
                type: String,
                default: '',
            },
            desc: {
                type: String,
                default: '',
            },
            spend: {
                type: Number | String,
                default: ''
            },
            column: {
                type: String | Array,
                default: ''
            },
            liability:{ //免责声明
                type: String,
                default: '',
            }
        },
        data() {
            return {
                boxTitle: this.title,
                descMess: this.desc,
                spendNum: [],
                columnTitle: [],
                dialogPlay:false,
                videoTitle:'',
                videoUrl: '',
                videoViewStyle:{
                    background:"url('"+require("@/assets/imgs/videoBg.png")+"') 100% 100%"
                },
                videoIcon:require("@/assets/imgs/playIcon.png"),
            }
        },
        created() {
            if (this.spend) {
                let spendArr = this.spend.split(",")
                let spendNumArr = []
                spendArr.map(s => {
                    let integral = this.$store.state.modelIntegralMap.get(s);
                    spendNumArr.push(integral);
                })
                // if(spendNumArr.length === 0){
                //     spendNumArr.push(0)
                // }
                if (spendNumArr.length > 1 && this.column === '') {
                    let sumValue = spendNumArr.reduce((sum, number) => {
                        return sum + number;
                    })
                    this.spendNum.push(sumValue)
                } else {
                    this.spendNum = spendNumArr
                }
            }

            if (this.column === '') {
                this.columnTitle.push(this.title)
            } else {
                let columnArr = this.column.split(",")
                this.columnTitle = columnArr
            }

        },
        methods: {
            openVideo(url) {
                let videoItem = videoData[url];
                if(!videoItem){
                    this.$message.error("暂无教学视频");
                    return;
                }
                this.dialogPlay = true;
                this.videoTitle = "教学视频：" + videoItem.name;
                this.videoUrl = videoItem.url;
            },
            isExist(url){
                let videoItem = videoData[url];
                if(!videoItem){
                   return false;
                }

                return true;
            },
            closeDialog() {
                this.dialogPlay = false
                this.videoUrl = ''
            }
        },
        watch: {
            title(newVal, oldVal) {
                this.boxTitle = newVal
            },
            desc(newVal, oldVal) {
                this.descMess = newVal
            },
        }
    }
</script>

<style lang="less" scoped>
    .box-card {
        padding: 18px 28px 18px 22px;
        background: #fff;
        box-shadow: 0 5px 15px 1px hsla(0, 0%, 75%, .2);
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 30px;

        .lbox {
            background: rgb(233, 242, 255);
            padding: 15px;
            border-radius: 8px;

            .title {
                font-size: 16px;
                font-weight: 700;
                color: rgb(27, 132, 255);
            }

            .desc {
                font-size: 12px;
                font-weight: 400;
                color: rgb(27, 132, 255);
                margin-left: 10px;
            }
        }
    }


    .box_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rbox {
            background: linear-gradient(90deg, rgb(218, 191, 255), rgb(64, 158, 255));
            border-radius: 19px;
            padding: 8px 25px;
            color: #fff;
            font-size: 14px;
            margin: 10px;
        }
    }

    .video_teach {
        font-size: 12px;
        padding-left:10px;
        cursor:pointer;
        color:#666;
        &:hover {
            color: #409EFF;
        }
    }

    .box-ext{
        overflow:hidden;
        padding:4px;
        
                    
        .box-tip{
            color: #505050;
            margin-top: 5px;
            margin-bottom: 5px;
            float:left;
            width:70%;
            p{
                font-size: 12px;
                line-height: 1.8;
            }
        }
        
        
        .video-view{
            float:right;
            width:180px;
            height:80px;
            background-size:100% 100%;
            position:relative;
            margin:0px 30px;
            cursor:pointer;

            .video-play{
                position: absolute;
                top: 0px;
                left: 0;
                width: 42px;
                right: 0px;
                bottom: 16px;
                margin: auto;
                height: 24px;
                box-shadow: 0px 0px 30px rgb(150 , 150 ,150 , 0.3);
            }

            .video-play:hover{
                transform:scale(1.1);
                box-shadow: 0px 0px 30px rgb(200 , 200 ,200 , 0.8);
            }

            .video-desc{
                color: #eee;
                font-size: 16px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 14px;
                text-align: center;
            }

        }
    }


</style>