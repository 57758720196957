import Vue from 'vue'
import Vuex from 'vuex'
import utils from "@/utils";

import {
  siteInfo,
  getMemberInfo,
  rechargeApi,
  interfacePriceList,
  getValidAdvert,
  inviteRegisterList
} from "@/request/api";


import {
  getApiAddConfig,
  expendConfigList
} from "@/request/flowApi"; //这里是引入请求


Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    title: process.env.VUE_APP_TITLE,
    modelIntegralMap: new Map(),
    loadContent: '', //load加载的文字关键字
    jumpFlag: false,
    token: null,
    loginFlag: false, //登录页面的弹窗
    changePWFlag: true, //忘记密码弹窗
    RegFlag: true,
    temporaryImg: require("../assets/imgs/temporary.png"),
    serviceQr: null, //个人专属二维码
    shareObj: null, //个人分享码
    fishFlag: false, //loading
    fishLoadNumber: 100000, //load加载的时间
    fishLoadContent: '', //load加载的文字关键字
    fishTraceId: '', //跟踪id
    fishTraceInfo: '', //跟踪信息
    // 礼品网
    goodsInfo: {}, //想购买的商品信息
    tenantInfo: {
      company: '', //
    }, //礼品网站信息
    goodsPass: false,
    vipList: [],
    vipColor: ['#f00', '#ff9800', '#ff00f7', "#001fff"],
    // vipbgUrl: [
    //   'https://www.dgshou.com/img/wz-2.deb2d8ec.png',
    //   'https://www.dgshou.com/img/zs-2.58056234.png',
    //   'https://www.dgshou.com/img/hj-2.799ef410.png',
    //   'https://www.dgshou.com/img/qt-2.3b207541.png'
    // ],
    rechargeList: [],
    currentVip: null,
    memberInfo: null,
    inviteRegisterList: [],
    copySuffix: '这是个私密工具箱，仅限特别邀请。',
    trafficAddConfig: [],
    trafficExpendConfig: [],
    siteInfo: {}, //站点信息
    advertList:[] //广告信息 
  },
  mutations: {
    setTitle(state, temp){
      state.title = temp;
    },
    alterLoadContent(state, temp) { //修改load加载的关键字
      state.loadContent = temp;
    },
    alterJumpFlag(state, temp) {
      state.jumpFlag = temp
    },
    alterLoginFlag(state, temp,) { //登录页面的弹窗
      state.loginFlag = temp
    },
    alterRegFlag(state, temp) {
      state.RegFlag = temp
    },
    alterChangePWFlag(state, temp) { //忘记密码
      state.changePWFlag = temp
    },
    alterToken(state, temp) {
      state.token = temp
    },
    clearToken(state) {
      state.token = null
      Vue.$cookies.remove('token', null)
    },
    serviceQr(state, temp) {
      state.serviceQr = temp
    },
    modelIntegralMap(state, temp) {
      if (temp != null) {
        if (temp instanceof Map) {
          state.modelIntegralMap = temp;
          return;
        }

        for (let key in temp) {
          let item = temp[key];
          state.modelIntegralMap.set(item.code, item.integral);
        }
      }
    },
    shareObj(state, temp) {
      state.shareObj = temp
    },
    // loading
    alterFishFlag(state, temp) {
      state.fishFlag = temp
    },
    // loading  时间
    alterFishLoadNumber(state, num) {
      state.fishLoadNumber = num
    },
    //修改load加载的关键字
    alterFishLoadContent(state, content) {
      state.fishLoadContent = content
    },

    //设置跟踪id
    alterTraceId(state, traceId) {
      state.fishTraceId = traceId;
    },
    //修改跟踪信息
    alterTraceInfo(state, traceInfo) {
      state.fishTraceInfo = traceInfo;
    },

    // 礼品网
    getGoodsInfo(state, obj) {
      state.goodsInfo = obj
    },
    alterGoodsPass(state, temp) {
      if (!state.goodsPass) {
        state.goodsPass = temp
      }
    },
    alertVipList(state, temp) {
      state.vipList = temp
    },
    alertCurrentVip(state, temp) {
      state.currentVip = temp
    },
    memberInfo(state, temp) {
      state.memberInfo = temp
    },
    alterRechargeList(state, temp) {
      state.rechargeList = temp
    },
    alterInviteRegisterList(state, temp) {
      state.inviteRegisterList = temp;
    },
    setTrafficAddConfig(state, temp) {
      state.trafficAddConfig = temp;
    },
    setTrafficExpendConfig(state, temp) {
      state.trafficExpendConfig = temp;
    },
    setSiteInfo(state, temp) {
      state.siteInfo = temp;
    },
    setAdvertList(state, temp){
      state.advertList = temp;
    }

  },
  actions: {
    async getMemberInfo({ commit }) {
      let { data } = await getMemberInfo();
      let shareObj = {
        code: data.inviteCode,
        inviteCode: data.inviteCode,
        inviteCount: data.inviteCount,
        payCount: data.payCount
      }
      shareObj.shref = utils.getAbsoluteUrl("page") + '?code=' + data.inviteCode

      commit("memberInfo", data);
      commit("shareObj", shareObj);
      commit("serviceQr", data.serviceQr);
      return data;
    },
    async getRechargeList({ commit }) {
      let { data } = await rechargeApi();
      let rechargeList = data.sort((a, b) => {
        return a.rechargeMoney - b.rechargeMoney;
      });

      console.log('初始化充值列表', rechargeList);
      commit("alterRechargeList", rechargeList);
      return rechargeList;
    },
    async getTrafficAddConfig({ commit, state }) {
      if (state.trafficAddConfig != null && state.trafficAddConfig.length > 0) {
        return state.trafficAddConfig;
      }

      let res = await getApiAddConfig();
      let list = res.list || [];
      commit("setTrafficAddConfig", list);
      return list;
    },
    async getTrafficExpendConfig({ commit, state }) {
      if (state.trafficExpendConfig != null && state.trafficExpendConfig.length > 0) {
        return state.trafficExpendConfig;
      }

      let res = await expendConfigList();
      let list = res.list || [];
      commit("setTrafficExpendConfig", list);
      return list;
    },
    async getSiteInfo({ commit }) {
      let { data } = await siteInfo();
      commit("setSiteInfo", data);
      commit("serviceQr", data.serviceQr);
      if(data.siteName){
        commit("setTitle", data.siteName);
        //修改标题
        document.title = data.siteName;
      }
      return data;
    },
    async getInterfacePrice({ commit }) {
      let { data } = await interfacePriceList();
      let integralList = data;
      //只能转换普通对象，不能转换Map
      sessionStorage.setItem("modelIntegral", JSON.stringify(integralList));
      commit('modelIntegralMap', integralList);
      return integralList;
    },
    async getValidAdvert({commit, state}){
      if(state.advertList != null && state.advertList.length > 0){
        return state.advertList;
      }
      let {data} = await getValidAdvert();
      let advertList = data.sort((a, b) => {
        return a.sort - b.sort;
      });

      commit("setAdvertList", advertList);
      return data;
    },
    async getInviteRegisterList({ commit, state }) {
      let { data } = await inviteRegisterList();
      commit("alterInviteRegisterList", data);
      return state.inviteRegisterList;
    },

  },


});

store.subscribeAction({
  error: (action, state, error) => {
    console.log(`error action ${action.type}`)
    console.error(error)
  }
});


export default store;