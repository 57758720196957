
/**
 * 菜单列表
 */
let menuList = [
  {
    name: "首页", //一级菜单名
    icon: "el-icon-s-finance",
    orderNum: 1, //菜单排序
    mark:require('@/assets/imgs/menuic1.gif'),
    func:false,
    url: "/page",
  },
  {
    name: "搜索工具", //一级菜单名
    icon: "el-icon-s-claim",
    orderNum: 2, //菜单排序
    tag:{
      name:"快速入池抢流量"
    },
    func:true,
    // mark:require('@/assets/imgs/menuic1.gif'),
    // tag: {
    //   name:'狂刷号监测',
    // },
    childList: [
      //相关子菜单
      // {
      //   name: "查标签/权重",
      //   url: "/LabelWeight", //菜单路由
      // },
      {
        limit:true,// 积分限免
        modelCode:"SDYHYJCWW",
        name: "一键查旺旺",
        url: "/QueryWant", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon10.png')
      },
      {
        modelCode:"WANGWANGDABIAO",
        name: "旺旺打标",
        url: "/WantWantMarking", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon9.png')
      },
      {
        modelCode:"GOODS_RANK",
        name: "关键字查排名",
        url: "/KeywordRanking", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon2.png')
      },
      {
        modelCode:"ITEM_CHECK_POOL",
        name: "搜索入池检测",
        url: "/ItemCheckInPool", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon2.png')
      },

     
      // {
        
      //   modelCode:"ORDERTEST",
      //   name: "淘客订单查询",
      //   url: "/TaokeOrderInquiry", //菜单路由
      //   funcIcon:require('@/assets/funcIcon/icon3.png')
      // },
      // {
      //   name: "关键字卡首屏",
      //   url: "/CardFirstScreen" //菜单路由
      // },
      
    ]
  },
  {
    name: "关键词分析", //一级菜单名
    icon: "el-icon-s-help",
    orderNum: 6, //菜单排序
    // mark:require('@/assets/imgs/menuic2.gif'),
    tag: {
      name:'搜索行为与需求',
    },
    func:true,
    childList: [
      // {
      //    modelCode:"KEY_ANALYSIS_SEARCH",
      //    name: "蓝海词查询",
      //    url: "/SearchListings", 
      //   funcIcon:require('@/assets/funcIcon/icon5.png')
      //  },
      // {
      //   modelCode:"POPULARITY_SEARCH_ANALYSIS",
      //   name: "人群画像分析",
      //   url: "/SearchPopularity",
      //   funcIcon:require('@/assets/funcIcon/icon5.png')
      // },
      {
        new: true,
        modelCode:"TB_SOAR_WORD",
        name: "淘宝搜索飙升词",
        url: "/TbRoarWord",
        funcIcon:require('@/assets/funcIcon/icon5.png')
      },
      {
        new: true,
        modelCode:"SUBWAY_HOT_WORD",
        name: "直通车热门词透视",
        url: "/SubwayHotWord",
        funcIcon:require('@/assets/funcIcon/icon5.png')
      },
      {
        modelCode:"TBRECOMMENDKEYS",
        name: "淘宝下拉词和长尾词推荐",
        url: "/TbRecommendKeys", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon2.png')
      },
      {
        modelCode:"TBTITLESPLIT",
        name: "淘宝标题权重选词分析",
        url: "/TbTitleSplit", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon2.png')
      },

    ]
  },

  {
    name: "卡首屏", //一级菜单名
    // mark:require('@/assets/imgs/menuic1.gif'),
    tag: {
      name:'黑科技卡位',
    },
    icon:'el-icon-s-ticket',
    orderNum: 3, //菜单排序
    func:true,
    childList: [
      //相关子菜单
      {
        
        modelCode:"KASHOUPING",
        name: "关键字卡首屏",
        url: "/keywordCard", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon17.png')

      },
      // {
      //   name: "相似/同款分析",
      //   url: "/SameItemAnalysis", //菜单路由
      // },
      {
        modelCode:"KASHOUPING",
        name: "多功能卡首屏",
        url: "/multifunctionCard", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon12.png')

      },
      {
        modelCode:"TAOKOULING,GET_TAOKOULING_DETAIL",
        name: "淘口令",
        url: "/NewPassword", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon11.png')

      },

    ]
  },
  
  // {
  //   name: "礼品单快递", //一级菜单名
  //   icon: "el-icon-star-on",
  //   orderNum: 7, //菜单排序
  //   tag:{
  //     name:'真实发货低至1.5元每单',
  //     color:'#f36109'
  //   },
  //   func:false,
  //   childList: [{
  //       name: "商品列表",
  //       url: "/goodsList" //礼品网
  //     },
  //     {
  //       name: "立即发货",
  //       url: "/member/send_order" //礼品网
  //     },
  //     {
  //       name: "订单列表",
  //       url: "/member/order" //礼品网
  //     },
  //     {
  //       name: "包裹列表",
  //       url: "/member/receipt" //礼品网
  //     },
  //   ]
  // },

  // {
  //   name: "浏览加购单", //一级菜单名
  //   orderNum: 7, //菜单排序
  //   icon: "el-icon-s-data",
  //   // mark:require('@/assets/imgs/menuic2.gif'),
  //   tag: {
  //     name:'稀释转化完善数据',
  //   },
  //   childList: [{
  //       name: "淘宝任务",
  //       url: "/manage/taobao" //流量网
  //     },
  //     {
  //       name: "京东任务",
  //       url: "/manage/jingdong" //流量网
  //     },
  //     {
  //       name: "拼多多任务",
  //       url: "/manage/pinduoduo" //流量网
  //     },
  //     {
  //       name: "任务查询",
  //       url: "/manage/taskNew" //流量网
  //     },
  //   ],
  // },
  
  {
    name: "商品透视", //一级菜单名
    icon: "el-icon-s-cooperation",
    orderNum: 1, //菜单排序
    tag: {
      name:'商家必用',
    },
    func:true,
    childList: [
      //相关子菜单
      {
        modelCode:"SPTSSPPJ",
        name: "商品评价",
        url: "/CommodityEvaluationV2", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon15.png')
      },
      {
        modelCode:"SPTSSPWD",
        name: "问大家",
        url: "/CommodityAnswer", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon6.png')
      },
      
      
      {
        modelCode:"SPSKUCOUNT",
        name: "商品SKU占比分析",
        url: "/SkuAccountedFor", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon16.png')
      },
      // {
      //   name: "商品买家秀",
      //   url: "/SellerShow", //菜单路由
      // },
     
      {
        modelCode:"HISTORICAL_PRICE",
        name: "商品历史价格",
        url: "/GoodsHistoryPrice", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon7.png')
      },
     
      {
        modelCode:"SPTSLMCX",
        name: "类目查询",
        url: "/CategoriesQuery", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon1.png')
      },
      {
        modelCode:"SPTSSXCX",
        name: "属性查询",
        url: "/AttributeQuery", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon21.png')
      },
      
      {
        name: "在线指数还原",
        url: "/OnLineExponentialReduction", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon21.png')
      },
      {
        name: "DSR智能计算器",
        url: "/DSRcalculator", //菜单路由
        funcIcon:require('@/assets/funcIcon/icon7.png')
      },
    ]
  },
 

  
  // {
  //   name: "自动作战表", //一级菜单名
  //   icon: "el-icon-s-management",
  //   orderNum: 4, //菜单排序
  //   func:false,
  //   url: "/plugin", //菜单路由
  //   // childList: [
  //     //相关子菜单
  //     // {
  //       // name: "成交关键字",
  //       // url: "/KeywordTransaction", //菜单路由
  //     // },
  //     // {
  //     //   name: "插件下载",
  //     //   url: "/plugin", //菜单路由
  //     // },
  //   // ]
  // },
  // {
  //   name: "单品排名及竞争分析", //一级菜单名
  //   icon: "el-icon-s-marketing",
  //   orderNum: 5, //菜单排序
  //   func:true,
  //   childList: [
  //     //相关子菜单
  //     {
  //       modelCode:"GOODS_RANK",
  //       name: "关键字查排名",
  //       url: "/KeywordRanking2", //菜单路由
  //       funcIcon:require('@/assets/funcIcon/icon2.png')
  //     },
  //     // {
  //     //   name: "相似/同款分析",
  //     //   url: "/SameItemAnalysis", //菜单路由
  //     // },
  //     // {
  //     //   modelCode:"GOODS_ANALYSIS_PERSPECTIVE,GOODS_ANALYSIS_PRODUCT_DETAIL",
  //     //   name: "竞品分析",
  //     //   url: "/JingPinAnalyse", //菜单路由
  //     //   funcIcon:require('@/assets/funcIcon/icon23.png')
  //     // },
  //   ]
  // },


  // {
  //   name: "待分配菜单", //一级菜单名
  //   orderNum: 5, //菜单排序
  //   childList: [
  //     //相关子菜单
  //     {
  //       name: "旺旺打标",
  //       url: "/WantWantMarking" //菜单路由
  //     },
  //     {
  //       name: "在线指数还原",
  //       url: "/OnLineExponentialReduction" //菜单路由
  //     },
  //     {
  //       name: "DSR智能计算器",
  //       url: "/DSRcalculator" //菜单路由
  //     },
  //     {
  //       name: "淘客订单查询",
  //       url: "/TaokeOrderInquiry" //菜单路由
  //     },
  //     {
  //       name: "关键字卡首屏",
  //       url: "/CardFirstScreen" //菜单路由
  //     }
  //   ]
  // }，

  

  // {
  //   name: "推广赚钱", //一级菜单名
  //   icon: "el-icon-star-on",
  //   orderNum: 8, //菜单排序
  //   tag:{
  //     name:'立即提现',
  //     color:'#cc9900'
  //   },
  //   func:false,
  //   url: "/extendmoney",
  // },

  // {
  //   name: "分站代理", //一级菜单名
  //   icon: "el-icon-star-on",
  //   orderNum: 8, //菜单排序
  //   func:false,
  //   url: "/cooperation",
  // },
  // {
  //   name: "工具箱插件", //一级菜单名
  //   icon: "el-icon-s-finance",
  //   orderNum: 6, //菜单排序
  //   // mark:require('@/assets/imgs/menuic1.gif'),
  //   tag: {
  //     name:'插件全免费',
  //   },
  //   func:false,
  //   url: "/plugin",

  //   // childList: [
  //   //      {
  //   //      name: "插件安装",
  //   //      url: "/plugin" 
  //   //    },
  //   // ]
  // },
  // {
  //   name: "补单计划表", //一级菜单名
  //   icon: "el-icon-s-finance",
  //   orderNum: 7, //菜单排序
  //   // mark:require('@/assets/imgs/menuic1.gif'),
  //   func:false,
  //   url: "/autoSchedule",
  // },
  // {
  //   name: "积分充值", //一级菜单名
  //   icon: "el-icon-star-on",
  //   orderNum: 9, //菜单排序
  //   mark:require('@/assets/imgs/menuic2.gif'),
  //   func:false,
  //   url: "/recharge",
  //   // tag:{
  //   //   name:'买一送二',
  //   //   color:'#d01e21'
  //   // },
  // },
  // {
  //   name: "电商资料库", //一级菜单名
  //   icon: "el-icon-s-marketing",
  //   orderNum: 6, //菜单排序
  //   func:true,
  //   childList: [
  //     //相关子菜单
  //     {
  //       new:true,
  //       name: "资料列表",
  //       url: "/datastoreList", //菜单路由
  //       funcIcon:require('@/assets/funcIcon/icon2.png')
  //     },

  //   ]
  // },
];

export default menuList;
