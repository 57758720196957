/**
 * Created by superman on 17/2/16.
 * http配置
 */

import axios from 'axios'
import store from '@/store'
import { Message, Loading } from 'element-ui';
import { notLogin } from "@/publicApi";
import qs from 'qs';


// axios 配置
const request = axios.create({
	timeout: 20000
});

request.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
request.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// http request 拦截器
request.interceptors.request.use(
	config => {
		//loading ，可选
		if (config.loading) {
			let text = "正在加载数据...";
			if (typeof config.loading == "string") {
				text = config.loading;
			}

			config.loading = Loading.service({
				lock: true,
				text: text,
				spinner: 'el-icon-loading',
				background: 'rgba(255, 255, 255, 0.7)'
			});
		}

		if (store.state.token) {
			config.headers.token = store.state.token
		}

		//转换数据格式
		if (config.data != null && typeof config.data != "string") {
			var postData = qs.stringify(config.data, {
				arrayFormat: 'repeat'
			});
			config.data = postData;
		}

		return config;
	},
	err => {
		return Promise.reject(err)
	},
)

// http response 拦截器
request.interceptors.response.use(
	(response) => {
		if (response.config.loading) {
			response.config.loading.close();
		}
		
		let { data } = response;

		if (data.code == 401) { // 401, token失效
			store.commit("clearToken");
			notLogin();
		}

		if (response.config.responseType == 'blob') {
			return response;
		}

		if (data.code != 0) {
			let errorMsg = data.msg || "未知错误，请稍后再试";
			//在新的实例上使用组件
			Message({
				showClose: true,
				message: errorMsg,
				type: "error",
			});
			//指向错误流程
			return Promise.reject(new Error(errorMsg));
		}

		return data;
	},
	(error) => {
		if (error.config.loading) {
			error.config.loading.close();
		}
		
		Message({
			showClose: true,
			message: error.message,
			type: "error",
		});
		// console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
		return Promise.reject(error)
	},
)
export { request }



// 流量网
// 淘宝 获取数据
export const getApiAddConfig = (data) => request.post('/api/traffic/config/addConfigList', data)
export const expendConfigList = (data) => request.post('/api/traffic/config/expendConfigList', data)
export const ApiRelsese = (data) => request.post('/api/traffic/taskNew/add', data)
export const getApiData = (data) => request.post('/api/traffic/taskNew/getDetail', data, {loading:true})
export const getApiDataBatch = (data) => request.post('/api/traffic/taskNew/getDetailBatch', data, {loading:true})
export const ApiTaskNewList = (data) => request.post('/api/traffic/taskNew/getList', data)
export const ApiTaskNewcancel = (data) => request.post('/api/traffic/taskNew/cancel', data)

