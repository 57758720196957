import axios from "axios"
import store from '@/store'
import { Loading, Message } from "element-ui";

import qs from 'qs'
import { notLogin } from "@/publicApi";


const baseURL = process.env.VUE_APP_BASE_URL;

const request = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 120000
})

// 请求拦截
request.interceptors.request.use(config => {
  var params = ''
  for (var key in config.data) {
    params = key; //取出传进来的参数
  }
  if (params == "params") { //是否=='params'，如果是params的话就给config大对象添加params配置参数，否则正常传值
    config['params'] = config.data.params
    config.data = undefined
  }

  //loading ，可选
  if (config.loading) {
    let text = "正在加载数据...";
    if (typeof config.loading == "string") {
      text = config.loading;
    }

    config.loading = Loading.service({
      lock: true,
      text: text,
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.7)'
    });
  }

  //config是个大对象，里面有很多自带的默认属性。
  // console.log(config); //这个config大对象是默认传递的，axios自带的，{url: "/mock/home", params: {…}, headers: {…}, transformRequest: Array(1), transformResponse: Array(1), …},其中： headers: {Accept: "application/json, text/plain, */*"}
  if (store.state.token != null) {
    config.headers.token = store.state.token //设置请求头，给请求头对象添加token属性(localStorage.getItem("key"))
  }
  // console.log("最新的", config); //里面的headers: {Accept: "application/json, text/plain, */*", token: "Lucy"}
  return config
}, err => {
  return Promise.reject(err)
})

// 响应拦截
request.interceptors.response.use(res => {
  if (res.config.loading) {
    res.config.loading.close();
  }

  //是否关闭遮罩
  if (res.config.closeLoading !== false) {
    store.commit("alterJumpFlag", false);
    store.commit("alterFishFlag", false);
  }

  // console.log("每一次接收到响应，都会先执行这里的代码，再去执行成功的那个回调函数then", res.data.code);
  if (res.data.code == 401) { // 401, token失效  
    store.commit("clearToken");
    notLogin();
  }

  if (res.data.code == 201) { //用于区别支付的轮询接口
    return res.data
  }

  if (res.data.code != 0) {
    //在新的实例上使用组件
    Message({
      showClose: true,
      message: res.data.msg,
      type: "error",
    });
    //指向错误流程
    return Promise.reject(new Error(res.data.msg));
  }

  return res.data

}, err => {
  if (err.config.loading) {
    err.config.loading.close();
  }

  Message({
    showClose: true,
    message: err.message,
    type: "error",
  });
  //是否关闭遮罩
  if (err.config.closeLoading !== false) {
    store.commit("alterJumpFlag", false);
    store.commit("alterFishFlag", false);
  }


  return Promise.reject(err)

})

//接口请求
export { request }


//获取站点信息
export const siteInfo = () => request.get("/api/public/siteInfo");
// 获取项目积分
export const interfacePriceList = (data) => request.post('/api/public/interfacePriceList', data, { closeLoading: false })
// 注册时获取手机验证码
export const sendVerificationCode = (data) => request.post('/register/sendVerificationCode', data)
// 会员注册
export const register = (data) => request.post('/register/addRegister', data)
// 会员登录
export const login = (data) => request.post('/api/login', data)
// 会员退出登录
export const logout = (data) => request.post('/api/logout', data)
// 个人中心
export const getMemberInfo = (data) => request.post('/api/v1/getMemberInfo', data);

// 获取积分消费分类
export const getInterfaceCategoryList = (data) => request.post('/api/public/interfaceCategory', data)

// 使用记录
export const getPointChangeLog = (data) => request.post('/api/v1/getPointChangeLog', data)

// 轮询订单状态
export const pollOrder = (data) => request.post('/api/v1/pollOrder', data)
//获取订单详情
export const viewOrderPay = (data) => request.post('/api/v1/viewOrderPay', data)
// 微信充值
export const Recharge = (data) => request.post('/api/v1/recharge', data)

//修改密码 
export const modificationPW = (data) => request.post('/sys/resetPassword', data)
// 修改密码  验证码
export const modificationCode = (data) => request.post('/sys/sendResetPasswordCode', data)

// 获取接口优惠信息
export const getInterfaceFreeInfo = (data) => request.post('/api/public/getInterfaceFreeInfo', data, { closeLoading: false })

// 获取接口优惠使用情况
export const queryFreeUseInfo = (data) => request.post('/api/memberDiscount/queryFreeUseInfo', data, { closeLoading: false })

// 获取邀请成员记录
export const queryInviteMember = (data) => request.post('/api/v1/queryInviteMember', data)
//系统消息
export const getUnreadList = (data) => request.post('/api/memberMsg/getUnreadList', data, { closeLoading: false })
//已读系统消息
export const readMsg = (data) => request.post('/api/memberMsg/readMsg', data, { closeLoading: false })
//优惠信息，获取充值优惠信息
export const rechargeApi = (data) => request.post('/api/public/rechargeList', data, { closeLoading: false })
//查看用户奖励列表
export const memberReward = (data) => request.post('/api/memberReward/list', data, { closeLoading: false })
// 提现
export const applyFinanceWithdraw = (data) => request.post('/api/memberReward/applyFinanceWithdraw', data, { closeLoading: false })
// 提现列表
export const withdrawList = (data) => request.post('/api/memberReward/withdrawList', data, { closeLoading: false })

//  提交建议
export const suggestMit = (data) => request.post('/api/memberAdvice/save', data)


//浏览行为
export const behaviorTick = (data) => request.post('/api/public/tick', data, { closeLoading: false });


//获取授权地址
export const authorize = (data) => request.post('/weixin/authorize', data);

//获取授权状态
export const getWxState = (data) => request.post('/weixin/getWxState', data);

//获取公告信息
export const getNoticeList = () => request.post('api/v1/getNoticeList');

//获取商品类别
export const goodsTypeList = () => request.post("api/public/goodsTypeList");

//获取有效仓库
export const goodsStoreList = () => request.post("api/public/goodsStoreList")

//获取商品信息列表
export const goodsInfoList = (data) => request.post("api/public/goodsInfoList", data);

//获取商品信息
export const getGoodsInfo = (data) => request.post("api/public/getGoodsInfo", data);

//获取快递费用
export const getExpressFeeList = (data) => request.post("api/public/getExpressFeeList", data);

//创建订单
export const createOrder = (data) => request.post("api/present/createOrder", data, { loading: true });

//订单列表
export const orderList = (data) => request.post("api/present/orderList", data);

//包裹列表
export const pkList = (data) => request.post("api/present/pkList", data);


//获取广告信息
export const getValidAdvert = () => request.get("api/public/getValidAdvert");

// 优惠信息
export const inviteRegisterList = (data) => request.post('api/public/inviteRegisterList', data, {closeLoading:false} )


// ------------------------------------------------------------------------------
/**
 * 
 * 外部接口合集
 * 
 */

// 默认列表
export const getSearchDefaultList = (data) => request.post('/external/sycm/findDefaultCorrelationAnalysis', data)
// 搜索列表
export const getSearchList = (data) => request.post('/external/sycm/searchKey', data)
// 搜索列表  详情
export const getSearchDetail = (data) => request.post('/external/sycm/searchKeyDetail', data)
// 获取跟踪id
export const createTraceId = (data) => request.post('/external/createTraceId', data, { closeLoading: false })
// 获取跟踪详情
export const getTraceInfo = (data) => request.post('/external/getTraceInfo', data, { closeLoading: false })


//所有类目
export const getAllCates = () => request.post('/external/xcm/getAllCates')
//淘宝飙升词
export const tbSoarWord = (data) => request.post('/external/xcm/tbSoarWord', data)
//直通车热门词透析
export const subwayHotWord = (data) => request.post('/external/xcm/subwayHotWord', data)

// 查标签/权重
export const checkWangwang = (data) => request.post('/external/check/wangWang', data)

// 找相似\同款  
export const queryExoGoodsList = (data) => request.post('/external/check/queryExoGoodsList', data)

// 旺旺打标 
export const wangWangMarking = (data) => request.post('/external/check/wangMarkingBatch', data)

// 获取旺旺打标记录 
export const getMarkingList = (data) => request.post('/api/tool/getMarkingList', data)

// 在线指数转化 
export const indexConversion = (data) => request.post('/external/check/indexConversion', data)

// DSR智能计算器查询 
export const dsrIntelligentComputing = (data) => request.post('/external/check/dsrIntelligentComputing', data)

// 淘客订单查询 
export const queryOrder = (data) => request.post('/external/check/queryOrder', data)
// 获取淘客订单历史记录 
export const queryOrderList = (data) => request.post('/api/tool/queryOrderList', data)

// 生成淘口令
export const clearTaokouling = (data) => request.post('/external/check/clearTaokouling', data)
// 解析淘口令
export const analysisTaokouling = (data) => request.post('/external/check/getTklDetail', data)
// 获取淘口令列表
export const clearTaokoulingList = (data) => request.post('/api/tool/clearTaokoulingList', data)

// 急速验号  授权跳转
export const toQrCode = (data) => request.post('/external/check/validateAccountQrCode', data)
// 急速验号查询
export const userCheck = (data) => request.post('/external/check/validateAccountInfo', data)


//类目查询的数据
export const getCategory = (data) => request.post('/external/goods/getCateGoryName', data)

// 请求属性查询的数据
export const getGoodsCateGoryInfo = (data) => request.post('/external/goods/getGoodsCateGoryInfo', data)

// 请求商品评价的数据
export const getGoodsFeedList = (data) => request.post('/external/goods/getGoodsFeedList', data)
export const getGoodsFeedListV2 = (data) => request.post('/external/goods/getGoodsFeedListV2', data)

// 竞品评价sku占比分析
export const goodsSkuCount = (data) => request.post('/external/goods/goodsSkuCount', data)

// 问大家
export const getAskEveryone = (data) => request.post('/external/goods/getAskEveryone', data)

//历史价格
export const goodsHistoryPrice = (data) => request.post('/external/goods/historyPrice', data);

// 关键字查排名  
export const queryGoodsTop = (data) => request.post('/external/goods/tb/getRank', data);
export const queryGoodsTopV1 = (data) => request.post('/external/goods/queryGoodsTop', data);
export const queryGoodsTopV2 = (data) => request.post('/external/goods/queryGoodsTopV2', data);

//关键词查排名2
export const queryGoodsTop2 = (data) => request.post('/external/goods/tb/getRank2', data)

// 生成卡首屏
export const kaShouPing = (data) => request.post('/external/kaShouPin/kaShouPing', data)

// 获取卡首屏列表
export const kaShouPingList = (data) => request.post('api/tool/kaShouPingList', data)

// 多功能卡首屏   获取商品信息
export const multiKaShouPing = (data) => request.post('/external/kaShouPin/multiKaShouPing', data, { closeLoading: false })

// 买家秀
export const getMaiJiaXiu = (data) => request.post('/external/getMaiJiaXiu', data)

//添加卡首屏数据
export const addMultifun = (data) => request.post('/api/tool/addMultifun', data);
//卡首屏数据列表
export const multifunList = (data) => request.post('/api/tool/multifunList', data);

//入池检测
export const itemCheckInPool = (data) => request.post("/external/goods/itemCheckInPool", data);
//入池检测列表
export const itemCheckInPoolList = (data) => request.post("/api/tool/checkInPoolList", data);

//淘宝下拉词
export const tbRecommendKeys = (data) => request.post("/external/goods/tbRecommendKeys", data);


//标题权重分析
export const tbTitleSplit = (data) => request.post("/external/goods/tbTitleSplit", data);
