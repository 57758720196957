export default {

  getMenuList() {
    return menuList
  },
  getTaskType(name){
    for (var i = 0; i < menuList.length; i++) {
      if (menuList[i].name == name) {
        return menuList[i]
      }
    }
  },
  getTaskIndex(num){
    return menuList[num];
  }
}
  var menuList = [{
        name: '流量任务',
        type: [{
            name: '京东搜索真实访客',
            taskType: '11',
            titleStatus:true,
            keyword:true,
          },
        ]
      },
      {
        name: '收藏任务',
        type: [{
            name: '商品收藏',
            taskType: '21',
            titleStatus:true,
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
          },
          {
            name: '店铺关注',
            taskType: '22',
            request:'店铺链接',
            shopStatus:true,
            requestDesc:'请输入店铺链接',
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
          },
          {
            name: '搜索收藏',
            taskType: '23',
            titleStatus:true,
            keyword:true,
          },
        ]
      },
      {
        name: '加购任务',
        type: [{
            name: '搜索加购',
            taskType: '31',
            keyword:true,
            titleStatus:true,
            keyword:true,
          },
          {
            name: '商品加购',
            taskType: '32',
            titleStatus:true,
            residenceTime_viewGoodIgnore:true,
            viewComment_attachIgnore:true,
          },
        ]
      },
    ]
