<template>
  <div class="advert-wrapper" v-if="advert">
    <el-image
      class="advert-image"
      :src="advert.source"
      @click="toRoute(advert.linkUrl)"
      :title="advert.name"
    ></el-image>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  props: {},
  data() {
    return {};
  },
  mounted() {
    let advert = this.advert;
    console.log("识别路由“" + this.$route.path + "”当前广告内容：", advert);
  },
  methods: {},
  computed: {
    advert() {
      //获取当前路由的广告
      let advert = null;
      let path = this.$route.path;
      let advertList = this.$store.state.advertList;
      if (advertList) {
        advertList.forEach((item) => {
          let pos = utils.getRelativePath(item.position || "");
          if (pos == path) {
            //找到数据
            advert = item;
          }
        });
      }
      return advert;
    },
  },
};
</script>

<style lang="less" scoped>
.advert-wrapper {
  margin-bottom: 10px;
  padding: 0px 20px;
  text-align:center;
}

.advert-image {
  border-radius: 8px;
}
</style>