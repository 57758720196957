
/**
 * 左侧菜单列表
 */
let funcMenuList = [
  // {
  //   modelCode:"KEY_ANALYSIS_SEARCH",
  //   name: "蓝海词查询",
  //   url: "/SearchListings", 
  //   funcIcon:require('@/assets/funcIcon/icon5.png')
  // },
  {
    modelCode:"ITEM_CHECK_POOL",
    name: "搜索入池检测",
    url: "/ItemCheckInPool", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon2.png')
  },
  {
    // limit:true,// 积分限免
    modelCode:"SDYHYJCWW",
    name: "一键查旺旺",
    url: "/QueryWant", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon10.png')
  },

  {
    modelCode:"WANGWANGDABIAO",
    name: "旺旺打标",
    url: "/WantWantMarking", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon9.png')
  },
  {
    // new: true,
    modelCode:"TB_SOAR_WORD",
    name: "搜索飙升词",
    url: "/TbRoarWord",
    funcIcon:require('@/assets/funcIcon/icon5.png')
  },
  {
    modelCode:"SUBWAY_HOT_WORD",
    name: "直通车热门词",
    url: "/SubwayHotWord",
    funcIcon:require('@/assets/funcIcon/icon5.png')
  },
 
  {
        
    modelCode:"KASHOUPING",
    name: "关键字卡首屏",
    url: "/keywordCard", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon17.png')

  },

  {
    modelCode:"KASHOUPING",
    name: "多功能卡首屏",
    url: "/multifunctionCard", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon12.png')
  },
  
  {
    modelCode:"TAOKOULING,GET_TAOKOULING_DETAIL",
    name: "淘口令生成",
    url: "/NewPassword", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon11.png')

  },
  // {
  //   modelCode:"VALIDATE_ACCOUNT_QR,VALIDATE_ACCOUNT_RESULT",
  //   name: "深度验号",
  //   url: "/ValidateAccount", //菜单路由
  //   funcIcon:require('@/assets/funcIcon/icon8.png')
  // },

  // {
  //   name: "淘宝流量预约",
  //   url: "/manage/taobao",
  //   funcIcon:require('@/assets/funcIcon/icon7.png')
  // },
  // {
  //   name: "京东流量预约",
  //   url: "/manage/jingdong",
  //   funcIcon:require('@/assets/funcIcon/icon7.png')
  // },
  // {
  //   name: "多多流量预约",
  //   url: "/manage/pinduoduo",
  //   funcIcon:require('@/assets/funcIcon/icon7.png')
  // },
  // {
  //   name: "流量任务查询",
  //   url: "/manage/taskNew", //流量网
  //   funcIcon:require('@/assets/funcIcon/icon7.png')
  // },


  {
    name: "在线指数还原",
    url: "/OnLineExponentialReduction", //菜单路由
    funcIcon:require('@/assets/funcIcon/icon21.png')
  },
// {
//   name: "礼品单快递",
//   url: "/goodsList",
//   funcIcon:require('@/assets/funcIcon/icon7.png')
// },

];



export default funcMenuList;
